import React, { useState, useMemo, useRef } from "react"
import { Link, useI18next } from "gatsby-plugin-react-i18next"
import ScrollAnimation from "react-animate-on-scroll"
import { graphql } from "gatsby"
import _orderBy from "lodash/orderBy"
import { Row, Col, Breadcrumb, Pagination, Select } from "antd"

import Layout from "../../app/layout"
import Seo from "../../components/seo"

import Container from "../../components/Container"
import SliderTestimoniFeatured from "../../components/SliderTestimoniFeatured"
import SendTestimoni from "../../components/SendTestimoni"
import TestimonyCard from "../../components/TestimonyCard"
import { ChevronRight } from "../../components/Icon"

const TestimoniPage = ({
  data: {
    aboutTestimonies: data = {
      highlight: [],
      testimonies: [],
    },
  },
}) => {
  let refTarget = useRef()

  const { Option } = Select
  const { t } = useI18next()
  const [filter, setFilter] = useState({
    type: "",
    sort: "",
    page: 1,
  })
  const filtered = useMemo(() => {
    let results = [...data.testimonies]

    if (filter.type) {
      results = data.testimonies.filter(d => d.category === filter.type)
    }
    if (filter.sort === "newest") {
      results = _orderBy(results, ["created_at"], "desc")
    }
    if (filter.sort === "az") {
      results = _orderBy(results, ["name"], "asc")
    }
    if (filter.sort === "za") {
      results = _orderBy(results, ["name"], "desc")
    }
    return results
  }, [filter, data.testimonies])

  const paging = page => {
    setFilter(p => ({ ...p, page }))

    if (refTarget.current) {
      window.scrollTo({ behavior: "smooth", top: refTarget.current.offsetTop })
    }
  }

  return (
    <Layout module_name="about" page_name="testimony">
      <Seo title={t("Testimoni")} module_name="about" page_name="testimony" />
      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Breadcrumb.Item>
            <Link to="/">{t("Beranda")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{t("Testimoni")}</Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <div className="section text-medium section-padding-top-less">
        <Container>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h1 className="section-title-huge">{t("Testimoni")}</h1>
          </ScrollAnimation>
          <SliderTestimoniFeatured sliderContent={data.highlight} />{" "}
        </Container>
        {data.testimonies.length > 0 && (
          <div className="filter-form" ref={refTarget}>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <Container>
                <Row>
                  <Col span={24} md={4}>
                    <Select
                      placeholder={t("Filter berdasarkan")}
                      onChange={type => setFilter(p => ({ ...p, type }))}
                      style={{ width: "100%" }}
                    >
                      <Option value="">{t("Semua")}</Option>
                      <Option value="awardee">Awardee</Option>
                      <Option value="grantees">Grantees</Option>
                    </Select>
                  </Col>
                  <Col span={24} md={4}>
                    <Select
                      placeholder={t("Urutkan berdasarkan")}
                      onChange={sort => setFilter(p => ({ ...p, sort }))}
                      style={{ width: "100%" }}
                    >
                      <Option value="newest">{t("Terbaru")}</Option>
                      <Option value="az">A - Z</Option>
                      <Option value="za">Z - A</Option>
                    </Select>
                  </Col>
                </Row>
              </Container>
            </ScrollAnimation>
          </div>
        )}
        <Container>
          <Row gutter={30} style={{ marginTop: 24 }}>
            {filtered
              .slice((filter.page - 1) * 6, filter.page * 6)
              .map((item, i) => (
                <Col span={24} md={8} key={i}>
                  <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                    <TestimonyCard item={item} withContent={true} />
                  </ScrollAnimation>
                </Col>
              ))}
          </Row>
          {data.testimonies.length > 6 && (
            <Row justify="center" style={{ marginBottom: 40 }}>
              <Pagination
                current={Number(filter.page)}
                total={filtered.length}
                onChange={paging}
                defaultPageSize={6}
              />
            </Row>
          )}
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <SendTestimoni />
          </ScrollAnimation>
        </Container>
      </div>
    </Layout>
  )
}

export default TestimoniPage

export const query = graphql`
  query ($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    aboutTestimonies(lang: { eq: $language }) {
      testimonies {
        category
        content
        created_at
        image
        institution_name
        name
        publish
        updated_at
      }
      highlight {
        category
        content
        created_at
        image
        institution_name
        is_highlight
        name
        publish
        updated_at
      }
    }
  }
`
