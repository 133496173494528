import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { Button, Row, Col, Typography } from "antd"

import Container from "../Container"

import "./MainSlider.less"

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

const MainSlider = ({ sliderContent }) => {
  const { Title } = Typography
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 481)
    }

    handleResize()
    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const renderMedia = item => {
    if (item.banner_video) {
      return (
        <div className="video-container">
          <video
            autoPlay
            muted
            loop
            playsInline
            className="main-slider-image hide-mobile video"
          >
            <source src={item.banner_video} type="video/mp4" />
          </video>
          <video
            autoPlay
            muted
            loop
            playsInline
            className="main-slider-image-mobile show-mobile video"
          >
            <source src={item.banner_video} type="video/mp4" />
          </video>
        </div>
      )
    }

    return (
      <>
        <div
          className="main-slider-image hide-mobile"
          style={{ backgroundImage: `url(${item.banner_image})` }}
        />
        <div
          className="main-slider-image-mobile show-mobile"
          style={{ backgroundImage: `url(${item.banner_image_mobile})` }}
        />
      </>
    )
  }

  return (
    <Swiper
      autoplay={{ delay: 5000, disableOnInteraction: true }}
      loop={true}
      slidesPerView={1}
      pagination={{ clickable: true }}
      className="main-slider-container"
    >
      {sliderContent.length > 0 &&
        sliderContent.map((item, i) => (
          <SwiperSlide key={i}>
            <div className="main-slider">
              {renderMedia(item)}
              <div className="main-slider-caption">
                <Container>
                  <Row>
                    <Col md={12} xs={24}>
                      <Title
                        style={{
                          color: isMobile ? "rgba(0,0,0, 0.85)" : "#FFF",
                        }}
                        level={2}
                      >
                        {item.title}
                      </Title>
                      <Button href={item.button_url} size="large">
                        {item.button_text}
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </SwiperSlide>
        ))}
    </Swiper>
  )
}

MainSlider.propTypes = {
  sliderContent: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      imageMobile: PropTypes.string,
      caption: PropTypes.string,
      captionColor: PropTypes.string,
      buttonText: PropTypes.string,
      buttonURL: PropTypes.string,
      banner_video: PropTypes.string,
    })
  ),
}

MainSlider.defaultProps = {
  sliderContent: PropTypes.shape({
    captionColor: "#000",
    buttonText: "Lebih Lanjut",
  }),
}

export default MainSlider
