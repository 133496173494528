import React, { useState, useMemo } from "react"
import { Link, graphql } from "gatsby"
import _orderBy from "lodash/orderBy"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Row, Col, Pagination, Breadcrumb, Select, Input } from "antd"

import Seo from "../../../components/seo"
import Layout from "../../../app/layout"

import { ChevronRight } from "../../../components/Icon"
import Container from "../../../components/Container"
import Masthead from "../../../components/Masthead"

const Angkatan = ({
  data: {
    awardeeDepartureGenerations: data = {
      generation: [],
      page_setting: {},
    },
  },
}) => {
  const { Option } = Select
  const { Search } = Input
  const { t } = useI18next()
  const { Item } = Breadcrumb
  const [filter, setFilter] = useState({
    page: 1,
    search: "",
    sort: "newest",
  })

  const filtered = useMemo(() => {
    let results = [...data.generation].map(d => {
      const indexedDate = d.created_at.split("-")

      return {
        ...d,
        indexed: d.generation_name.toLowerCase().replace(" ", ""),
        indexedSearch: [d.generation_name, d.generation_code]
          .join("")
          .toLowerCase(),
        indexedDate: new Date(
          `${indexedDate[2]}-${indexedDate[1]}-${indexedDate[0]}`
        ).getTime(),
      }
    })

    if (filter.search) {
      results = results.filter(d =>
        d.indexedSearch.toLowerCase().includes(filter.search.toLowerCase())
      )
    }

    if (filter.sort === "newest") {
      results = _orderBy(results, ["indexedDate"], "desc")
    }

    if (filter.sort === "az") {
      results = _orderBy(results, ["indexed"], "asc")
    }

    if (filter.sort === "za") {
      results = _orderBy(results, ["indexed"], "desc")
    }

    return results
  }, [data.generation, filter])

  return (
    <Layout>
      <Seo title="Angkatan" />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Item>
            <Link to="/">{t("Beranda")}</Link>
          </Item>
          <Item>Persiapan Keberangkatan</Item>
          <Item>{data.page_setting.header_title}</Item>
        </Breadcrumb>
      </Container>

      <Masthead
        image={data.page_setting.header_image}
        title={data.page_setting.header_title}
        useVideo={false}
        textBottom={t("Selengkapnya")}
      />
      <div className="filter-form">
        <Container>
          <Row>
            <Col span={24} md={20}>
              <Search
                placeholder={t("Cari Nama Angkatan")}
                style={{ width: "100%" }}
                onSearch={e => setFilter(p => ({ ...p, search: e }))}
              />
            </Col>
            {/* <Col span={24} md={4}>
              <Select placeholder="Filter Angkatan" style={{ width: "100%" }}>
                <Option value="a">Nama Angkatan</Option>
                <Option value="b">Tanggal Pelaksanaan</Option>
                <Option value="c">Jumlah Peserta</Option>
              </Select>
            </Col> */}
            <Col span={24} md={4}>
              <Select
                placeholder={t("Urutkan berdasarkan")}
                style={{ width: "100%" }}
                onChange={e => setFilter(p => ({ ...p, sort: e }))}
              >
                <Option value="newest">{t("Terbaru")}</Option>
                <Option value="az">A - Z</Option>
                <Option value="za">Z - A</Option>
              </Select>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="section text-medium">
        <Container>
          <div className="angkatan-list">
            {filtered
              .slice((filter.page - 1) * 10, filter.page * 10)
              .map((d, i) => (
                <div key={i}>
                  <Row>
                    <Col span={24} md={8}>
                      <p>
                        <span className="label">{d.generation_code}</span>{" "}
                        <br />
                        {d.generation_name}
                      </p>
                    </Col>
                    <Col span={24} md={8}>
                      <p>
                        <span className="label">
                          {t("Tanggal Pelaksanaan")}
                        </span>{" "}
                        <br />
                        {d.implementation_date}
                      </p>
                    </Col>
                    <Col span={24} md={8}>
                      <p>
                        <span className="label">{t("Jumlah Peserta")}</span>{" "}
                        <br />
                        {d.number_of_participant}
                      </p>
                    </Col>
                  </Row>
                  <hr />
                </div>
              ))}
          </div>
          <Row justify="center" style={{ marginBottom: 40, marginTop: 40 }}>
            <Pagination
              current={filter.page}
              total={filtered.length}
              onChange={page => setFilter(p => ({ ...p, page }))}
              defaultPageSize={10}
            />
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query awardeeDepartureGenerationsQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    awardeeDepartureGenerations(lang: { eq: $language }) {
      generation {
        generation_code
        generation_name
        number_of_participant
        implementation_date
        created_at
      }
      page_setting {
        header_title
        header_image
        header_use_video
        header_video_thumbnail
        header_video_url
        slug
      }
    }
  }
`
export default Angkatan
