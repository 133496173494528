import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { PlayCircleFilled } from "@ant-design/icons"
import { Modal, Button } from "antd"
import YouTube from "react-youtube"
import ScrollAnimation from "react-animate-on-scroll"

import "./Masthead.less"

const Masthead = ({
  title,
  image,
  useVideo,
  videoId,
  playBtnText,
  textBottom,
}) => {
  const [visible, setVisible] = useState(false)
  const videoIframe = useRef(null)

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    videoIframe.current.internalPlayer.pauseVideo()
  }

  const opts = {
    height: "390",
    width: "100%",
    playerVars: {
      autoplay: 1,
      rel: 0,
      modestbranding: 0,
      showinfo: 0,
    },
  }

  return (
    <div className="masthead">
      <div className="masthead-content">
        <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
          <h1>{title}</h1>
        </ScrollAnimation>

        <div className="meta">
          <div dangerouslySetInnerHTML={{ __html: textBottom }} />
          <div className="scroll-downs">
            <div className="mousey">
              <div className="scroller"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="masthead-media">
        <div
          className="masthead-image"
          style={{ backgroundImage: `url(${image})` }}
        >
          {useVideo && (
            <>
              <Button
                onClick={showModal}
                icon={<PlayCircleFilled />}
                className="btn-outline-white"
                size="large"
              >
                {playBtnText || "Putar Video"}
              </Button>

              <Modal
                width="60%"
                className="video-modal"
                visible={visible}
                onCancel={handleCancel}
                footer={false}
              >
                <YouTube ref={videoIframe} videoId={videoId} opts={opts} />
              </Modal>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

Masthead.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  useVideo: PropTypes.bool,
  videoId: PropTypes.string,
  playBtnText: PropTypes.string,
  textBottom: PropTypes.string,
}

Masthead.defaultProps = {
  useVideo: false,
}

export default Masthead
