import React from "react"
import { Link as GatsbyLink, graphql } from "gatsby"
import Seo from "../components/seo"
import Layout from "../app/layout"

import { Row, Col, Breadcrumb } from "antd"

import Container from "../components/Container"
import { ChevronRight } from "../components/Icon"
import NavigasiNews from "../components/NavigasiNews"
import { useI18next } from "gatsby-plugin-react-i18next"

const Prasyarat = ({
  data: {
    settings: {
      precondition: data = {
        page_setting: {},
        page_contents: [],
      },
    },
  },
}) => {
  const { t } = useI18next()
  const page_contents = data.page_contents.map((d, i) => ({
    ...d,
  }))
  return (
    <Layout>
      <Seo title={data.page_setting.title} />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Breadcrumb.Item>
            <GatsbyLink to="/">{t("Beranda")}</GatsbyLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{data.page_setting.title}</Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <div className="section text-medium section-padding-top-less">
        <Container>
          <Row justify="space-between">
            <Col span={24} md={{ order: 1, span: 17 }}>
              <h1 className="section-title-huge">{data.page_setting.title}</h1>
              <article className="article">
                {page_contents.map((d, i) => (
                  <div key={i}>
                    <h4 id={"anchor-" + i}>{d.title}</h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: d.description,
                      }}
                    />
                  </div>
                ))}
              </article>
            </Col>
            <Col span={24} md={6}>
              <div className="sticky-sidebar">
                <div className="hide-mobile">
                  <NavigasiNews data={page_contents} limit={10} />
                  <hr style={{ margin: "30px 0 15px 0px" }} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default Prasyarat

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    settings(lang: { eq: $language }) {
      precondition {
        page_setting {
          slug
          title
          updated_at
        }
        page_contents {
          description
          title
        }
      }
    }
  }
`
