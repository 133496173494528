import React from "react"
import Seo from "../../components/seo"
import Layout from "../../app/layout"
import { Link, graphql } from "gatsby"

import { Row, Col, Breadcrumb, Button } from "antd"
import { ChevronRight } from "../../components/Icon"

import Container from "../../components/Container"
import Masthead from "../../components/Masthead"
import { useI18next } from "gatsby-plugin-react-i18next"

const InstrumenPage = ({
  data: {
    invesmentInstrumentPortfolios: data = {
      page_contents: {
        instruments: [],
        portfolios: [],
      },
      page_setting: {},
    },
  },
}) => {
  const { t } = useI18next()
  const { Item } = Breadcrumb
  return (
    <Layout module_name="investment" page_name="instrument-portfolio">
      <Seo
        title={data.page_setting.header_title}
        module_name="investment"
        page_name="instrument-portfolio"
      />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Item>
            <Link to="/">{t("Beranda")}</Link>
          </Item>
          <Item>{data.page_setting.header_title}</Item>
        </Breadcrumb>
      </Container>

      <Masthead
        image={data.page_setting.header_image}
        title={data.page_setting.header_title}
        useVideo={!!data.page_setting.header_use_video}
        videoId={data.page_setting.header_video_url}
        textBottom={t("Selengkapnya")}
      />

      <div className="section section-secondary text-medium">
        <Container>
          <div className="text-center">
            <h2 className="section-title">{t("Instrumen Investasi")}</h2>
          </div>
          <Row
            gutter={40}
            justify="space-between"
            style={{ margin: "32px -15px 0 -15px" }}
          >
            {data.page_contents.instruments.map((item, i) => (
              <Col span={24} md={8} key={i} style={{ padding: "0 15px" }}>
                <img src={item.icon} alt={item.title} />
                <h5 style={{ marginTop: 12 }}>{item.title}</h5>
                <p style={{ marginTop: 12 }}>{item.description}</p>
              </Col>
            ))}
          </Row>
          <p className="text-center" style={{ marginTop: 25 }}>
            {t(
              "*Investasi pada instrument berikut bertujuan untuk menjaga kestabilan nominal dana abadi di masa mendatang."
            )}
          </p>
        </Container>
      </div>

      <div className="section section-third text-medium">
        <Container>
          <Row>
            <Col span={24} md={12}>
              <h3 className="section-title">
                {data.page_setting.content_title}
              </h3>
            </Col>
            <Col span={24} md={12}>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.page_setting.content_description,
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="section text-medium">
        <Container>
          <div className="text-center">
            <h2 className="section-title">{t("Portofolio Pilihan")}</h2>
          </div>
          <Row
            gutter={40}
            justify="center"
            style={{ marginTop: 32, marginLeft: "-15px", marginRight: "-15px" }}
          >
            {data.page_contents.portfolios.map((item, i) => (
              <Col
                span={24}
                md={8}
                key={i}
                style={{ paddingLeft: "15px", paddingRight: "15px" }}
              >
                <img src={item.icon} alt={item.title} />
                <h5 style={{ marginTop: 12 }}>{item.title}</h5>
                <p style={{ marginTop: 12 }}>{item.description}</p>
              </Col>
            ))}
          </Row>
        </Container>
      </div>

      <div className="section">
        <Container>
          <hr style={{ marginBottom: 24 }} />
          <Row justify="space-between">
            <Col span={24} md={18}>
              <h3 className="section-title">
                {t("Anda Memiliki Pertanyaan?")}
              </h3>
            </Col>
            <Col flex="none">
              <Button size="large">
                <Link to="/informasi/hubungi-kami/">{t("Hubungi Kami")}</Link>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}
export default InstrumenPage

export const query = graphql`
  query invesmentInstrumentPortfoliosQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    invesmentInstrumentPortfolios(lang: { eq: $language }) {
      page_setting {
        content_description
        content_title
        created_at
        header_image
        header_title
        header_video_url
        header_use_video
        slug
        updated_at
      }
      page_contents {
        instruments {
          created_at
          description
          icon
          title
          updated_at
        }
        portfolios {
          updated_at
          title
          icon
          description
          created_at
        }
      }
    }
  }
`
