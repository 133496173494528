import React from "react"
import { Link as GatsbyLink, graphql } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Row, Col, Breadcrumb, Button } from "antd"
import PageNotFound from "../404"
import Seo from "../../components/seo"
import Layout from "../../app/layout"
import Container from "../../components/Container"
import LinkUnduhan from "../../components/LinkUnduhan"
import NavigasiNews from "../../components/NavigasiNews"
import { ChevronRight } from "../../components/Icon"

const ResearchSingle = ({
  pageContext: {
    data: dataall = {
      id: {
        content_files: [],
        content_items: [],
      },
      en: {
        content_files: [],
        content_items: [],
      },
    },
    language = "id",
  },
}) => {
  const { t } = useI18next()
  const { Item } = Breadcrumb
  const data = dataall[language]
  if (!data) return <PageNotFound />
  return (
    <Layout>
      <Seo title={data.header_title} />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Item>
            <GatsbyLink to="/">{t("Beranda")}</GatsbyLink>
          </Item>
          <Item>{data.submenu_name}</Item>
          <Item>{data.header_title}</Item>
        </Breadcrumb>
      </Container>

      <div className="section text-medium section-padding-top-less">
        <Container>
          <Row justify="space-between">
            <Col span={24} md={{ order: 1, span: 17 }}>
              <h1 className="section-title-huge">{data.header_title}</h1>
              <article className="article">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.header_description,
                  }}
                />
                {data.content_items
                  .sort((a, b) => a.order - b.order)
                  .map((d, i) => (
                    <div key={i}>
                      <h4 id={"anchor-" + i}>{d.content_title}</h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: d.content_description,
                        }}
                      />
                    </div>
                  ))}
              </article>
            </Col>
            <Col span={24} md={6}>
              <div className="sticky-sidebar">
                <div className="hide-mobile">
                  <NavigasiNews data={data.content_items} limit={10} />
                  <hr style={{ margin: "30px 0 15px 0px" }} />

                  <Button
                    block
                    size="small"
                    target="_blank"
                    href={data.registration_url}
                    style={{ marginBottom: 15 }}
                  >
                    {t("DAFTAR")}
                  </Button>
                </div>
                {data.content_files.length > 0 && (
                  <LinkUnduhan
                    Links={data.content_files.map(d => ({
                      header_title: d.title,
                      items: d.file_items.map(dd => ({
                        file: dd.file,
                        item_title: dd.file_title,
                      })),
                    }))}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default ResearchSingle

export const query = graphql`
  query ResearchSingleQuery($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
