import React from "react"
import { Link, graphql } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Row, Col, Breadcrumb, Button } from "antd"
import Layout from "../../app/layout"
import Seo from "../../components/seo"
import Container from "../../components/Container"
import Masthead from "../../components/Masthead"
import { ChevronRight } from "../../components/Icon"

const DanaAbadi = ({
  data: {
    invesmentDonations: data = {
      page_contents: {
        deposits: [],
        types: [],
      },
      page_setting: {},
    },
  },
}) => {
  const { Item } = Breadcrumb
  const { t } = useI18next()

  return (
    <Layout module_name="investment" page_name="donation">
      <Seo
        title={data.page_setting.header_title}
        module_name="investment"
        page_name="donation"
      />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Item>
            <Link to="/">{t("Beranda")}</Link>
          </Item>
          <Item>{data.page_setting.header_title}</Item>
        </Breadcrumb>
      </Container>

      <Masthead
        image={data.page_setting.header_banner_image}
        title={data.page_setting.header_title}
        useVideo={!!data.page_setting.header_use_video}
        videoId={data.page_setting.header_video_url}
        textBottom={t("Selengkapnya")}
      />

      <div className="section section-secondary padding-bottom-none">
        <div className="overlap-section-left align-middle">
          <div className="overlap-section-col overlap-section-col-img">
            <img
              src={data.page_setting.section_1_image}
              alt="tipe dana abadi"
              className="overlap-bottom"
            />
          </div>
          <div className="overlap-section-col overlap-section-col-content">
            <h2 className="section-title">
              {data.page_setting.section_1_title}
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: data.page_setting.section_1_description,
              }}
            />
          </div>
        </div>
      </div>

      <div className="section text-medium" style={{ marginTop: 40 }}>
        <Container>
          <div className="text-center">
            <h2 className="section-title">{t("Sumber Dana Abadi")}</h2>
          </div>
          <Row
            gutter={40}
            justify="space-between"
            style={{ margin: "32px 0 0 0" }}
          >
            {data.page_contents.types.map((item, i) => (
              <Col
                span={24}
                md={8}
                key={i}
                className="lined-section"
                style={{ padding: 0 }}
              >
                <h5 style={{ marginTop: 12 }}>{item.title}</h5>
                <div
                  style={{ marginTop: 12 }}
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              </Col>
            ))}
          </Row>
        </Container>

        <div
          className="overlap-section-right align-middle"
          style={{ marginTop: 48 }}
        >
          <div className="overlap-section-col overlap-section-col-img">
            <img
              src={data.page_setting.section_2_image}
              alt="tipe dana abadi"
              style={{ width: "100%" }}
            />
          </div>
          <div className="overlap-section-col overlap-section-col-content">
            <div
              dangerouslySetInnerHTML={{
                __html: data.page_setting.section_2_description,
              }}
            />
          </div>
        </div>
      </div>

      <div className="section text-medium">
        <Container>
          <div className="text-center" style={{ marginBottom: 50 }}>
            <h2 className="section-title">
              {t("Kelolaan Dana Abadi Lainnya")}
            </h2>
          </div>

          {data.page_contents.deposits.map((item, i) => (
            <div key={i}>
              <Row
                gutter={40}
                justify="space-between"
                align="middle"
                style={{ margin: "32px -15px 0 -15px" }}
              >
                <Col
                  span={24}
                  md={{ span: 12, order: i % 2 === 0 ? 1 : 0 }}
                  xs={24}
                  style={{
                    marginBottom: 24,
                    padding: "0 15px",
                  }}
                >
                  <img src={item.image} alt={item.title} width="100%" />
                </Col>
                <Col span={24} md={12} xs={24} style={{ padding: "0 15px" }}>
                  <h5 style={{ marginTop: 12 }}>{item.title}</h5>
                  <div
                    style={{ marginTop: 12 }}
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </Col>
              </Row>
            </div>
          ))}
        </Container>
        <Container>
          <hr style={{ marginBottom: 24, marginTop: 24 }} />
          <Row justify="space-between">
            <Col span={24} md={18}>
              <h3 className="section-title">
                {t("Anda Memiliki Pertanyaan?")}
              </h3>
            </Col>
            <Col flex="none">
              <Button size="large">
                <Link to="/informasi/hubungi-kami/">{t("Hubungi Kami")}</Link>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}
export default DanaAbadi

export const query = graphql`
  query invesmentDonationsQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    invesmentDonations(lang: { eq: $language }) {
      page_setting {
        header_title
        header_banner_image
        header_use_video
        header_video_url
        section_1_description
        section_1_image
        section_1_title
        section_2_description
        section_2_image
        slug
        created_at
      }
      page_contents {
        deposits {
          image
          updated_at
          title
          description
          created_at
        }
        types {
          created_at
          description
          title
          updated_at
        }
      }
    }
  }
`
