import React, { useRef } from "react"
import SwiperCore, { Navigation, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import PropTypes from "prop-types"

import TestimonyCard from "../TestimonyCard"

import "./TestimoniesWithSlider.less"

SwiperCore.use([Navigation, A11y])

const TestimoniesWithSlider = ({ testimonies }) => {
  const prevRef = useRef(null)
  const nextRef = useRef(null)

  return (
    <>
      <div ref={prevRef} className="custom-swiper-button-prev">
        &#8249;
      </div>
      <div ref={nextRef} className="custom-swiper-button-next">
        &#8250;
      </div>

      <Swiper
        spaceBetween={30}
        slidesPerView={3}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onSwiper={swiper => {
          // Ensure Swiper has been initialized before updating the navigation
          swiper.params.navigation.prevEl = prevRef.current
          swiper.params.navigation.nextEl = nextRef.current
          swiper.navigation.update()
        }}
      >
        {testimonies.slice(0, 6).map((item, i) => (
          <SwiperSlide key={`news-${i}`}>
            <TestimonyCard item={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}

TestimoniesWithSlider.propTypes = {
  testimonies: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      institution_name: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
      uploaded_date: PropTypes.string.isRequired,
      content: PropTypes.string, // Optional for content
    })
  ),
}

export default TestimoniesWithSlider
