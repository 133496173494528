import React, { useState, useMemo } from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import {
  Row,
  Pagination,
  Col,
  Breadcrumb,
  Card,
  Typography,
  Select,
} from "antd"
import _orderBy from "lodash/orderBy"
import { useI18next } from "gatsby-plugin-react-i18next"

import Seo from "../../../components/seo"
import Layout from "../../../app/layout"

import Container from "../../../components/Container"
import { ChevronRight } from "../../../components/Icon"

const FeaturedAlumni = ({
  data: {
    allAwardeeAlumniFeaturedAlumni: { nodes: data = [] },
  },
}) => {
  const { Item } = Breadcrumb
  const { Title } = Typography
  const { Option } = Select
  const { t } = useI18next()
  const [filter, setFilter] = useState({
    page: 1,
    sort: "newest",
  })
  const filtered = useMemo(() => {
    let results = [...data]

    if (filter.sort === "newest") {
      results = _orderBy(results, ["created_at"], "desc")
    }

    if (filter.sort === "az") {
      results = _orderBy(results, ["name"], "asc")
    }

    if (filter.sort === "za") {
      results = _orderBy(results, ["name"], "desc")
    }

    return results
  }, [data, filter])

  return (
    <Layout>
      <Seo title="Featured Alumni" />
      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Item>
            <Link to="/">{t("Beranda")}</Link>
          </Item>
          <Item>Alumni</Item>
          <Item>Featured Alumni</Item>
        </Breadcrumb>
      </Container>
      <div className="section text-medium">
        <Container>
          <h1 className="section-title-huge">Featured Alumni</h1>
        </Container>
        <div className="filter-form">
          <Container>
            <Row>
              <Col span={24} md={4}>
                <Select
                  placeholder={t("Urutkan berdasarkan")}
                  style={{ width: "100%" }}
                  onChange={e => setFilter(p => ({ ...p, sort: e }))}
                >
                  <Option value="newest">{t("Terbaru")}</Option>
                  <Option value="az">A - Z</Option>
                  <Option value="za">Z - A</Option>
                </Select>
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
          <Row gutter={30} style={{ marginTop: 24 }}>
            {filtered.length === 0 && (
              <Col span={24} md={8}>
                Tidak ada data.
              </Col>
            )}
            {filtered
              .slice((filter.page - 1) * 6, filter.page * 6)
              .map((item, i) => (
                <Col span={24} md={8} key={i}>
                  <Card
                    className="card-testi"
                    bordered={false}
                    cover={<img alt={item.job_title} src={item.image} />}
                  >
                    <Title level={4} style={{ marginBottom: 0 }}>
                      {item.name}
                    </Title>
                    <p>{item.job_title}</p>
                    <div>
                      <date>
                        {item.university_name} • {item.major}
                      </date>
                    </div>
                    <div className="text-medium" style={{ marginTop: 15 }}>
                      <div dangerouslySetInnerHTML={{ __html: item.content }} />
                    </div>
                  </Card>
                </Col>
              ))}
          </Row>
          {data.length > 6 && (
            <Row justify="center" style={{ marginBottom: 40, marginTop: 40 }}>
              <Pagination
                current={filter.page}
                total={filtered.length}
                onChange={page => setFilter(p => ({ ...p, page }))}
                defaultPageSize={6}
              />
            </Row>
          )}
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query allAwardeeAlumniFeaturedAlumniQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allAwardeeAlumniFeaturedAlumni(filter: { lang: { eq: $language } }) {
      nodes {
        job_title
        image
        created_at
        content
        major
        name
        university_name
      }
    }
  }
`

export default FeaturedAlumni
