import React, { useMemo, useState } from "react"
import { graphql, Link } from "gatsby"
import { Row, Col, Select, Input, Card, Button, Alert, Breadcrumb } from "antd"
import ScrollAnimation from "react-animate-on-scroll"
import { useI18next } from "gatsby-plugin-react-i18next"
import Layout from "../../../app/layout"
import Seo from "../../../components/seo"
import Container from "../../../components/Container"
import Masthead from "../../../components/Masthead"
import SliderTestimoni from "../../../components/SliderTestimoni"
import { ChevronRight } from "../../../components/Icon"

import "./karir.less"

const CareerPage = ({
  data: {
    careers: {
      testimony = {
        item: [],
      },
      job = {
        item: [],
      },
      page_setting = {},
    },
  },
}) => {
  const { Item } = Breadcrumb
  const { Option } = Select
  const { Search } = Input
  const { t } = useI18next()
  const [filter, setFilter] = useState({
    location: " ",
    search: "",
  })

  const filtered = useMemo(() => {
    let results = [...job.item]
    if (filter.location !== " ") {
      results = results.filter(
        d => d.job_type.toLowerCase() === filter.location.toLowerCase()
      )
    }
    if (filter.search) {
      results = results.filter(d =>
        d.job_title.toLowerCase().includes(filter.search.toLowerCase())
      )
    }
    return results
  }, [job.item, filter.location, filter.search])

  return (
    <Layout module_name="information" page_name="careers">
      <Seo title="Karir" module_name="information" page_name="careers" />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Item>
            <Link to="/">{t("Beranda")}</Link>
          </Item>
          <Item>{page_setting.header_title}</Item>
        </Breadcrumb>
      </Container>

      <Masthead
        image={page_setting.header_image}
        title={page_setting.header_title}
        videoId={page_setting.header_video_url}
        useVideo={!!page_setting.header_use_video}
        textBottom={t("Mengapa Bergabung Bersama Kami")}
      />

      <div className="section section-secondary text-medium">
        <Container>
          <div className="text-center">
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <h2 className="section-title-huge">{t("Mengapa LPDP")}</h2>
            </ScrollAnimation>
          </div>
          <Row
            gutter={40}
            justify="space-between"
            style={{ margin: "32px -15px 0 -15px" }}
          >
            <Col span={24} md={8} style={{ padding: "0 15px" }}>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <img
                  src={page_setting.about_logo_1}
                  alt={page_setting.about_title_1}
                />
                <h4 style={{ marginTop: 12 }}>{page_setting.about_title_1}</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: page_setting.about_description_1,
                  }}
                  style={{ marginTop: 12 }}
                />
              </ScrollAnimation>
            </Col>
            <Col span={24} md={8} style={{ padding: "0 15px" }}>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <img
                  src={page_setting.about_logo_2}
                  alt={page_setting.about_title_2}
                />
                <h4 style={{ marginTop: 12 }}>{page_setting.about_title_2}</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: page_setting.about_description_2,
                  }}
                  style={{ marginTop: 12 }}
                />
              </ScrollAnimation>
            </Col>
            <Col span={24} md={8} style={{ padding: "0 15px" }}>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <img
                  src={page_setting.about_logo_3}
                  alt={page_setting.about_title_3}
                />
                <h4 style={{ marginTop: 12 }}>{page_setting.about_title_3}</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: page_setting.about_description_3,
                  }}
                  style={{ marginTop: 12 }}
                />
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="section section-third karir-form">
        <Container>
          <div className="text-center">
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <h3 className="section-title-secondary">
                {t("Bergabung dengan Kami")}
              </h3>
            </ScrollAnimation>
          </div>
        </Container>
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <div className="karir-search">
            <Container>
              <Row>
                <Col span={24} md={6}>
                  <Select
                    placeholder="Tipe"
                    size="large"
                    value={filter.location}
                    onChange={e => setFilter(p => ({ ...p, location: e }))}
                    style={{ width: "100%" }}
                  >
                    {[
                      {
                        value: " ",
                        label: t("Semua Tipe"),
                      },
                      {
                        value: "fulltime",
                        label: t("Full time"),
                      },
                      {
                        value: "Contract",
                        label: t("Kontrak"),
                      },
                      {
                        value: "Internship",
                        label: t("Intern"),
                      },
                    ].map(item => (
                      <Option value={item.value}>{item.label}</Option>
                    ))}
                  </Select>
                </Col>
                <Col span={24} md={18}>
                  <Search
                    onSearch={e => setFilter(p => ({ ...p, search: e }))}
                    placeholder={t("Cari Posisi...")}
                    size="large"
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </ScrollAnimation>
      </div>

      <div className="section text-medium">
        <Container>
          <Row gutter={20}>
            {filtered.map((item, i) => (
              <Col
                span={24}
                sm={12}
                md={8}
                key={i}
                style={{ marginBottom: 20 }}
              >
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <Card
                    className="karir-card"
                    actions={[
                      <Button type="link">
                        <Link to={"/informasi/karir/" + item.slug}>
                          {t("Selengkapnya")}
                        </Link>
                      </Button>,
                    ]}
                  >
                    <h3>{item.job_title}</h3>
                    <p>
                      {item.job_type} • {item.job_location}
                    </p>
                  </Card>
                </ScrollAnimation>
              </Col>
            ))}
          </Row>

          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <Alert
              className="karir-alert"
              banner
              message={page_setting.join_with_us_title}
              type="warning"
              description={
                <div
                  dangerouslySetInnerHTML={{
                    __html: page_setting.join_with_us_general_information,
                  }}
                />
              }
            />
          </ScrollAnimation>
        </Container>
      </div>

      {testimony.item.length > 0 && (
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <div className="section text-medium" style={{ overflow: "hidden" }}>
            <Container>
              <h3
                style={{ marginBottom: 30 }}
                className="section-title-secondary"
              >
                {t("Dengar Pengalaman Mereka")}
              </h3>

              <SliderTestimoni sliderContent={testimony.item} />
            </Container>
          </div>
        </ScrollAnimation>
      )}
    </Layout>
  )
}
export default CareerPage

export const query = graphql`
  query CareerPageQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    careers(lang: { eq: $language }) {
      testimony {
        item {
          content
          created_at
          name
          photo
          job_position
        }
      }
      page_setting {
        join_with_us_general_information
        about_description_1
        about_description_2
        about_description_3
        about_logo_1
        about_logo_3
        about_logo_2
        about_title_1
        about_title_2
        about_title_3
        header_image
        header_title
        header_use_video
        header_video_url
        join_with_us_title
      }
      job {
        item {
          slug
          registration_start_date
          registration_end_date
          job_type
          job_title
          job_location
          job_code
          created_at
          publish
        }
      }
    }
  }
`
