import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import CountUp from "react-countup"
import VisibilitySensor from "react-visibility-sensor"
import { Link, useI18next } from "gatsby-plugin-react-i18next"
import { Button, Modal, Row, Col, Typography } from "antd"
import ScrollAnimation from "react-animate-on-scroll"

import Layout from "../app/layout"

import Seo from "../components/seo"
import Container from "../components/Container"
import MainSlider from "../components/MainSlider"
import CardSmartLink from "../components/CardSmartLink"
import Blockquote from "../components/Blockquote"
import SectionWithVideo from "../components/SectionWithVideo"
import CardNews from "../components/CardNews"
import LiveVideo from "../components/LiveVideo"
import NewsWithSlider from "../components/NewsWithSlider"
import TestimoniesWithSlider from "../components/TestimoniesWithSlider"
import StorySlider from "../components/StorySlider"
import TestimonyCard from "../components/TestimonyCard"

const IndexPage = ({ data: { homePage: data, allNews, lives } }) => {
  const { t } = useI18next()
  const news =
    allNews.nodes.length > 0
      ? [allNews.nodes[0].highlighted_news].concat(allNews.nodes[0].item)
      : []
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { Title } = Typography
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (data.pop_up.publish === 1) {
      setIsModalOpen(true)
    }
  }, [data.pop_up.publish])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 481)
    }

    handleResize() // Call on mount to set initial value
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const handleOk = () => {
    setIsModalOpen(false)
    window.open(data.pop_up.button_url, "_blank", "noopener,noreferrer")
  }

  return (
    <Layout module_name="home" page_name="home">
      <Seo title={t("Home")} module_name="home" page_name="home" />

      <MainSlider sliderContent={data.banner} />

      {data.information.length > 0 && (
        <div className="section">
          <Container>
            <Row gutter={{ xs: 8, sm: 16, md: 60 }}>
              {data.information.map((item, i) => (
                <Col span={24} md={8} key={`main-slider-${i}`}>
                  <CardSmartLink
                    image={item.icon}
                    title={item.title}
                    desc={item.description}
                    buttonURL={item.button_url}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      )}

      {news.length > 0 && (
        <div className="section" style={{ paddingTop: 80 }}>
          <Container>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <div className="text-center">
                <h3 className="section-title">{t("Berita Terbaru")}</h3>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={2}>
              {isMobile ? (
                <Row gutter={30} style={{ marginTop: 30, marginBottom: 30 }}>
                  {news.slice(0, 3).map((item, i) => (
                    <Col md={8} key={`news-${i}`}>
                      <CardNews
                        title={item.title}
                        category={item.news_category}
                        image={item.image}
                        url={item.slug}
                        date={item.created_at}
                      />
                    </Col>
                  ))}
                </Row>
              ) : (
                <Row
                  style={{
                    marginTop: 30,
                    marginBottom: 30,
                    position: "relative",
                  }}
                >
                  <NewsWithSlider news={news} />
                </Row>
              )}
              <div className="text-center">
                <Button size="large" href="/informasi/berita/">
                  {t("Lihat Semua Berita")}
                </Button>
              </div>
            </ScrollAnimation>
          </Container>
        </div>
      )}

      {data.story.length > 0 && (
        <>
          <div className="section section-secondary section-big-spaceBottom text-medium">
            <Container>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <div className="text-center">
                  <h3 className="section-title">{t("Cerita")}</h3>
                </div>
              </ScrollAnimation>
            </Container>
          </div>

          <Container>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={2}>
              <div className="slider-offsetTop">
                <StorySlider sliderContent={data.story} />
              </div>
            </ScrollAnimation>
          </Container>
        </>
      )}

      {data.page_setting &&
        data.page_setting.section_testimony_name.length > 0 && (
          <div className="section">
            <Container>
              <Row>
                <Col md={14} offset={6}>
                  <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                    <Blockquote
                      content={data.page_setting.section_testimony_text}
                      name={data.page_setting.section_testimony_name}
                    />
                  </ScrollAnimation>
                </Col>
              </Row>
            </Container>
          </div>
        )}

      {data.page_setting && (
        <SectionWithVideo
          videoBg={data.page_setting.section_about_video_thumbnail}
          playBtnText={t("Putar Video")}
          videoId={data.page_setting.section_about_video_id}
        >
          <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
            <h3 className="section-title">
              {data.page_setting.section_about_title}
            </h3>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} delay={2}>
            <div
              dangerouslySetInnerHTML={{
                __html: data.page_setting.section_about_content,
              }}
            />
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} delay={3}>
            <h4 className="section-subtitle" style={{ marginTop: 20 }}>
              {t("Sebaran Penerima Layanan")}
              <span>per {data.page_setting.section_about_tanggal_update}</span>
            </h4>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} delay={4}>
            <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
              {({ isVisible }) => (
                <Row gutter={20} style={{ marginTop: 24, marginBottom: 24 }}>
                  {data.page_setting.section_about_details.map((item, i) => (
                    <Col span={24} md={8} key={`details-${i}`}>
                      <h4 className="semi-bold" style={{ marginBottom: 0 }}>
                        {isVisible ? (
                          <CountUp
                            end={item.amount}
                            duration={1}
                            separator={"."}
                            decimals={Number(item.amount) % 1 === 0 ? 0 : 2}
                            decimal=","
                          />
                        ) : null}
                        <sub style={{ bottom: 0, marginLeft: "4px" }}>
                          {item.label_name}
                        </sub>
                      </h4>
                      <p>{item.title}</p>
                    </Col>
                  ))}
                </Row>
              )}
            </VisibilitySensor>
            <Button className="btn-outline-white" size="large">
              <Link to="/tentang/selayang-pandang">
                {t("Lihat Lebih Lanjut")}
              </Link>
            </Button>
          </ScrollAnimation>
        </SectionWithVideo>
      )}

      {data.page_setting && data.page_setting.is_display_live === 1 && (
        <div className="section">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <LiveVideo
              image={`https://img.youtube.com/vi/${lives.video_url}/0.jpg`}
              title={lives.title}
              desc={lives.short_description}
              video_url={lives.video_url}
            />
          </ScrollAnimation>
        </div>
      )}

      {data.testimony.length > 0 && (
        <div className="section">
          <Container>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <div className="text-center">
                <h3 className="section-title">{t("Temu Mereka")}</h3>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={2}>
              {isMobile ? (
                <Row gutter={30} style={{ marginTop: 24 }}>
                  {data.testimony.slice(0, 3).map((item, i) => (
                    <Col span={24} md={8} key={i}>
                      <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <TestimonyCard item={item} />
                      </ScrollAnimation>
                    </Col>
                  ))}
                </Row>
              ) : (
                <Row
                  gutter={30}
                  style={{
                    marginTop: 30,
                    marginBottom: 30,
                    position: "relative",
                  }}
                >
                  <TestimoniesWithSlider testimonies={data.testimony} />
                </Row>
              )}
              <div className="text-center">
                <Button size="large">
                  <Link to="/tentang/testimoni">{t("Lihat Lebih Lanjut")}</Link>
                </Button>
              </div>
            </ScrollAnimation>
          </Container>
        </div>
      )}

      {data.pop_up && data.pop_up.publish === 1 && (
        <Modal
          className="popup-modal"
          visible={isModalOpen}
          footer={[
            <Button key="ok" type="primary" onClick={handleOk}>
              {data.pop_up.button_text}
            </Button>,
          ]}
          onCancel={() => setIsModalOpen(false)}
        >
          <Title level={3} className="text-center">
            {data.pop_up.title}
          </Title>

          <img src={data.pop_up.image} alt={data.pop_up.title} width="100%" />

          <div
            className="text-center text-highlight"
            dangerouslySetInnerHTML={{ __html: data.pop_up.description }}
          ></div>
        </Modal>
      )}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomePageQuery($language: String!) {
    lives(lang: { eq: $language }) {
      content
      short_description
      slug
      title
      event_date
      event_time
      video_url
      broadcast_platform
    }
    homePage(lang: { eq: $language }) {
      banner {
        banner_image
        banner_image_mobile
        button_text
        button_url
        text_color
        title
        order
        banner_video
      }
      information {
        icon
        button_url
        description
        title
        order
      }
      page_setting {
        created_at
        is_display_live
        section_about_content
        section_about_tanggal_update(formatString: "DD MMMM YYYY")
        section_about_title
        section_about_video_id
        section_about_video_thumbnail
        section_live_event_date(formatString: "DD MMMM YYYY")
        section_live_short_description
        section_live_speaker_name
        section_live_speaker_title
        section_live_start_time
        section_live_title
        section_live_video_id
        section_testimony_name
        section_testimony_text
        updated_at
        section_about_details {
          amount
          label_name
          order
          title
        }
      }
      pop_up {
        button_text
        button_url
        description
        image
        title
        publish
      }
      story {
        news_category
        title
        slug
        description
        writer_name
        photographer_name
        image
        thumbnail_image
        caption_image
        created_at
      }
      testimony {
        image
        name
        institution_name
        content
        uploaded_date
        category
        publish
        created_at
        updated_at
      }
    }
    allNews(
      sort: { fields: item___created_at, order: DESC }
      filter: { lang: { eq: $language } }
    ) {
      nodes {
        highlighted_news {
          title
          slug
          news_category
          image
          created_at
        }
        item {
          title
          slug
          news_category
          image
          created_at
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
