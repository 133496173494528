import React from "react"
import { graphql } from "gatsby"
import { Link, useI18next } from "gatsby-plugin-react-i18next"
import ScrollAnimation from "react-animate-on-scroll"
import { Row, Col, Breadcrumb } from "antd"

import Layout from "../../../app/layout"
import Seo from "../../../components/seo"
import Container from "../../../components/Container"

import CardTeam from "../../../components/CardTeam"
import { ChevronRight } from "../../../components/Icon"

const PengawasPage = ({
  data: {
    aboutManagementSupervisors: data = {
      page_setting: {},
      items: [],
    },
  },
}) => {
  const { Item } = Breadcrumb
  const { t } = useI18next()

  return (
    <Layout module_name="about" page_name="dewan-pengawas">
      <Seo
        title={t("Dewan Pengawas")}
        module_name="about"
        page_name="dewan-pengawas"
      />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Item>
            <Link to="/">{t("Beranda")}</Link>
          </Item>
          <Item>{data.page_setting.page_title}</Item>
        </Breadcrumb>
      </Container>

      <div className="section text-medium section-padding-top-less">
        <Container>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h1 className="section-title-huge">
              {data.page_setting.page_title}
            </h1>
          </ScrollAnimation>
          <Row gutter={30} style={{ marginTop: 40 }} justify="center">
            {data.items
              .sort((a, b) => (a.order > b.order ? 1 : -1))
              .map((item, i) => (
                <Col span={24} sm={12} md={8} key={i}>
                  <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                    <CardTeam
                      image={item.photo}
                      nama={item.name}
                      status={item.position}
                      jabatan={item.jabatan}
                      btnText={t("Selengkapnya")}
                      deskripsi={item.description}
                    />
                  </ScrollAnimation>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default PengawasPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "index", "page"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    aboutManagementSupervisors(lang: { eq: $language }) {
      page_setting {
        page_name
        page_title
        slug
      }
      items {
        jabatan
        name
        order
        photo
        position
        publish
        description
      }
    }
  }
`
