import React from "react"
import { Link as GatsbyLink, graphql } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Row, Col, Breadcrumb } from "antd"

import Layout from "../../app/layout"
import Seo from "../../components/seo"
import Container from "../../components/Container"
import LinkUnduhan from "../../components/LinkUnduhan"
import NavigasiNews from "../../components/NavigasiNews"
import { ChevronRight } from "../../components/Icon"

const InformasiPublik = ({
  data: {
    publicInformation: {
      content: { page_title, submenu_name, page_description, items = [] } = {},
      file,
    } = {},
  },
}) => {
  const { Item } = Breadcrumb
  const { t } = useI18next()

  const ContentSection = ({ title, description }) => (
    <div>
      {title && <h4>{title}</h4>}
      {description && <div dangerouslySetInnerHTML={{ __html: description }} />}
    </div>
  )

  return (
    <Layout module_name="information" page_name="informasi-publik">
      <Seo
        title={submenu_name}
        module_name="information"
        page_name="informasi-publik"
      />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Item>
            <GatsbyLink to="/">{t("Beranda")}</GatsbyLink>
          </Item>
          <Item>{submenu_name}</Item>
        </Breadcrumb>
      </Container>

      <div className="section text-medium">
        <Container>
          <Row justify="space-between">
            <Col span={24} md={{ order: 1, span: 17 }}>
              <h1 className="section-title-huge">{page_title}</h1>
              <article className="article">
                {page_description && (
                  <div dangerouslySetInnerHTML={{ __html: page_description }} />
                )}
                {items.map((item, index) => (
                  <ContentSection
                    key={index}
                    title={item.content_title}
                    description={item.content_description}
                  />
                ))}
              </article>
            </Col>
            <Col span={24} md={6}>
              <div offsetTop={80} className="sticky-sidebar">
                <div className="hide-mobile">
                  {items.length > 0 && <NavigasiNews data={items} limit={10} />}
                  <hr style={{ margin: "30px 0" }} />
                </div>
                {file && <LinkUnduhan Links={file} />}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default InformasiPublik

export const query = graphql`
  query publicInformationQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    publicInformation(lang: { eq: $language }) {
      content {
        page_description
        page_title
        submenu_name
        items {
          content_description
          content_title
        }
      }
      file {
        header_title
        id
        items {
          file
          file_id
          item_title
        }
      }
    }
  }
`
