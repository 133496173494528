import React from "react"
import PropTypes from "prop-types"

import "./Laporan.less"

const Laporan = ({ title, image, fileurl }) => (
  <a
    href={fileurl}
    download
    rel="noopener noreferrer"
    target="_blank"
    className="laporan-item"
  >
    <div className="laporan-item-inside">
      <div
        className="laporan-item-img"
        style={{ backgroundImage: `url(${image})` }}
      ></div>
    </div>
    <h3 className="laporan-item-title">{title}</h3>
  </a>
)

Laporan.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  fileurl: PropTypes.string,
}

export default Laporan
