import React from "react"
import { Link, graphql } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Row, Col, Breadcrumb } from "antd"
import Layout from "../../app/layout"
import Seo from "../../components/seo"
import Container from "../../components/Container"
import Masthead from "../../components/Masthead"
import Laporan from "../../components/Laporan"
import { ChevronRight } from "../../components/Icon"

const RisproTechBrief = ({
  data: {
    granteeRisproTechBriefs: data = {
      page_setting: {},
      files: [],
    },
  },
}) => {
  const { Item } = Breadcrumb
  const { t } = useI18next()

  return (
    <Layout>
      <Seo title={data.page_setting.header_title} />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Item>
            <Link to="/">{t("Beranda")}</Link>
          </Item>
          <Item>{data.page_setting.header_title}</Item>
        </Breadcrumb>
      </Container>

      <Masthead
        image={data.page_setting.header_banner_image}
        title={data.page_setting.header_title}
        useVideo={false}
        textBottom={t("Selengkapnya")}
      />

      <div className="section section-secondary padding-bottom-none">
        <div className="overlap-section-left align-middle">
          <div className="overlap-section-col overlap-section-col-img">
            <img
              src={data.page_setting.content_image}
              alt="tipe dana abadi"
              className="overlap-bottom"
            />
          </div>
          <div className="overlap-section-col overlap-section-col-content">
            <h2 className="section-title">{data.page_setting.content_title}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: data.page_setting.content_description,
              }}
            />
          </div>
        </div>
      </div>

      <div className="section text-medium" style={{ marginTop: 40 }}>
        <Container>
          <Row gutter={40} style={{ margin: "0 -15px" }}>
            {data.files.map((item, i) => (
              <Col span={24} md={6} key={i} style={{ padding: "0 15px" }}>
                <Laporan
                  title={item.title}
                  image={item.thumbnail_image}
                  fileurl={item.file}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </Layout>
  )
}
export default RisproTechBrief

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    granteeRisproTechBriefs(lang: { eq: $language }) {
      page_setting {
        content_description
        content_image
        content_title
        created_at
        header_title
        header_banner_image
        header_use_video
        slug
      }
      files {
        file
        publish
        thumbnail_image
        title
      }
    }
  }
`
