import React from "react"
import PropTypes from "prop-types"
import { Card } from "antd"

import "./CardWithIcon.less"

const CardWithIcon = ({ children, theme = "outline" }) => (
  <Card bordered={false} className={`card-icon ${theme}`}>
    <div className="card-icon-meta">{children}</div>
  </Card>
)

CardWithIcon.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.oneOf(["outline", "fill"]),
}

export default CardWithIcon
