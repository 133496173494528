import React from "react"
import { Link, graphql } from "gatsby"
import { Row, Col, Breadcrumb } from "antd"
import { useI18next } from "gatsby-plugin-react-i18next"

import Layout from "../../../app/layout"
import Seo from "../../../components/seo"
import { ChevronRight } from "../../../components/Icon"
import Container from "../../../components/Container"
import Masthead from "../../../components/Masthead"

const PengayaanKebijakan = ({
  data: {
    awardeeLanguagePolicies: data = {
      page_setting: {},
      facilities: [],
    },
  },
}) => {
  const { t } = useI18next()
  const { Item } = Breadcrumb

  return (
    <Layout>
      <Seo title={data.page_setting.header_title} />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Item>
            <Link to="/">{t("Beranda")}</Link>
          </Item>
          <Item>Pengayaan Bahasan</Item>
          <Item>{data.page_setting.header_title}</Item>
        </Breadcrumb>
      </Container>

      <Masthead
        image={
          data.page_setting.header_video_thumbnail ||
          data.page_setting.header_image
        }
        title={data.page_setting.header_title}
        videoId={data.page_setting.header_video_url}
        useVideo={!!data.page_setting.header_use_video}
        textBottom={t("Selengkapnya")}
      />

      <div className="section section-secondary padding-bottom-none text-medium">
        <div className="overlap-section-left align-middle">
          <div className="overlap-section-col overlap-section-col-img">
            <img
              src={data.page_setting.purpose_image}
              alt={data.page_setting.purpose_title}
              className="overlap-bottom"
            />
          </div>
          <div className="overlap-section-col overlap-section-col-content">
            <h2 className="section-title">{data.page_setting.purpose_title}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: data.page_setting.purpose_description,
              }}
            />
          </div>
        </div>
      </div>

      <div
        className="section text-medium padding-bottom-none"
        style={{ marginTop: 40 }}
      >
        <Container>
          <Row gutter={40} style={{ margin: "0 -15px" }}>
            <Col span={24} md={12} style={{ padding: "0 15px" }}>
              <h2 className="section-title">
                {data.page_setting.program_type_title}
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.page_setting.program_type_description,
                }}
              />
            </Col>
            <Col span={24} md={12} style={{ padding: "0 15px" }}>
              <h2 className="section-title">{data.page_setting.venue_title}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.page_setting.venue_description,
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="section text-medium">
        <Container>
          <Row gutter={40} style={{ margin: "0 -15px" }}>
            <Col span={24} md={12} style={{ padding: "0 15px" }}>
              <h2 className="section-title">{t("Syarat Penerima Program")}</h2>
            </Col>
            <Col span={24} md={12} style={{ padding: "0 15px" }}>
              <div
                className="list-large"
                dangerouslySetInnerHTML={{
                  __html: data.page_setting.requirement_program,
                }}
              />
            </Col>
          </Row>
        </Container>

        <div
          className="overlap-section-right align-middle"
          style={{ marginTop: 48 }}
        >
          <div className="overlap-section-col overlap-section-col-img">
            <img
              src={data.page_setting.facility_image}
              alt="Fasilitas"
              width="100%"
            />
          </div>
          <div className="overlap-section-col overlap-section-col-content">
            <h2 className="section-title">
              {data.page_setting.facility_title}
            </h2>
            <ul className="list-large list-image">
              {data.facilities.map((d, i) => (
                <li key={i}>
                  <img src={d.icon} alt="" /> <span>{d.facility_name}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query awardeeLanguagePoliciesQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    awardeeLanguagePolicies(lang: { eq: $language }) {
      page_setting {
        facility_image
        facility_title
        header_image
        header_title
        header_use_video
        header_video_thumbnail
        header_video_url
        program_type_description
        program_type_title
        purpose_description
        purpose_image
        purpose_title
        requirement_program
        slug
        venue_description
        venue_title
      }
      facilities {
        icon
        facility_name
      }
    }
  }
`
export default PengayaanKebijakan
