import React, { useMemo, useState } from "react"
import { Link, graphql } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import _orderBy from "lodash/orderBy"
import {
  Row,
  Col,
  Breadcrumb,
  Select,
  Typography,
  Pagination,
  Input,
} from "antd"
import Layout from "../../app/layout"
import Seo from "../../components/seo"
import Container from "../../components/Container"
import Masthead from "../../components/Masthead"
import { ChevronRight } from "../../components/Icon"

const DaftarGrantees = ({
  data: {
    granteeListOfGrantees: data = {
      page_setting: {},
      grantees: [],
    },
  },
}) => {
  const { Option } = Select
  const { Title } = Typography
  const { Search } = Input
  const { t } = useI18next()
  const [filter, setFilter] = useState({
    search: "",
    type: " ",
    sort: "newest",
    page: 1,
  })

  const filtered = useMemo(() => {
    let results = [...data.grantees]
    if (filter.search) {
      results = data.grantees
        .map(d => ({
          ...d,
          indexed: [
            d.head_of_research_name,
            d.proposal_title,
            d.research_focus,
            d.institution,
          ].join(" "),
        }))
        .filter(d =>
          d.indexed.toLowerCase().includes(filter.search.toLowerCase())
        )
    }

    if (filter.type !== " ") {
      results = results.filter(d => d.rispro_category === filter.type)
    }
    if (filter.sort === "newest") {
      results = _orderBy(results, ["created_at"], "desc")
    }
    if (filter.sort === "az") {
      results = _orderBy(results, ["proposal_title"], "asc")
    }
    if (filter.sort === "za") {
      results = _orderBy(results, ["proposal_title"], "desc")
    }
    return results
  }, [data.grantees, filter.sort, filter.type, filter.search])

  const listRispro = data.grantees
    .map(d => `${d.rispro_category}`)
    .reduce((acc, val) => {
      if (!acc.includes(val)) {
        acc.push(val)
      }
      return acc
    }, [])

  return (
    <Layout>
      <Seo title={data.page_setting.title} />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Breadcrumb.Item>
            <Link to="/">{t("Beranda")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{data.page_setting.title}</Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <Masthead
        image={data.page_setting.banner_image}
        title={data.page_setting.title}
        useVideo={false}
        textBottom={t("Selengkapnya")}
      />
      <div className="filter-form">
        <Container>
          <Row>
            <Col span={24} md={16}>
              <Search
                placeholder={t(
                  "Cari Periset, Judul Riset, Institusi, atau Fokus di sini..."
                )}
                style={{ width: "100%" }}
                onSearch={e => {
                  setFilter(p => ({ ...p, search: e }))
                }}
              />
            </Col>
            <Col span={24} md={4}>
              <Select
                placeholder={t("Filter Bedasarkan")}
                value={filter.type}
                style={{ width: "100%" }}
                onChange={e => setFilter(p => ({ ...p, type: e }))}
              >
                {[
                  { value: " ", label: t("Semua") },
                  ...listRispro.map(d => ({ value: d, label: d })),
                ].map(d => {
                  return (
                    <Option key={d.value} value={d.value}>
                      {d.label}
                    </Option>
                  )
                })}
              </Select>
            </Col>
            <Col span={24} md={4}>
              <Select
                placeholder={t("Urutkan berdasarkan")}
                value={filter.sort}
                style={{ width: "100%" }}
                onChange={e => setFilter(p => ({ ...p, sort: e }))}
              >
                <Option value="newest">{t("Terbaru")}</Option>
                <Option value="az">A - Z</Option>
                <Option value="za">Z - A</Option>
              </Select>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="section text-medium">
        <Container>
          {filtered
            .slice((filter.page - 1) * 10, filter.page * 10)
            .map((d, i) => (
              <div className="daftar-grantees-list" key={i}>
                <p className="label" style={{ marginBottom: 0 }}>
                  {d.rispro_category}
                </p>
                <Title level={4} style={{ marginBottom: 24 }}>
                  {d.proposal_title}
                </Title>
                <Row>
                  <Col span={24} md={6}>
                    <p>
                      <span className="label">{t("Ketua Riset")}</span> <br />
                      {d.head_of_research_name}
                    </p>
                  </Col>
                  <Col span={24} md={6}>
                    <p>
                      <span className="label">{t("Institusi")}</span> <br />
                      {d.institution}
                    </p>
                  </Col>
                  <Col span={24} md={6}>
                    <p>
                      <span className="label">{t("Fokus Riset")}</span> <br />
                      {d.research_focus}
                    </p>
                  </Col>
                  <Col span={24} md={6}>
                    <p>
                      <span className="label">{t("Tahun Kontrak")}</span> <br />
                      {d.contract_year}
                    </p>
                  </Col>
                </Row>

                <hr />
              </div>
            ))}
          <Row justify="center" style={{ marginBottom: 40, marginTop: 40 }}>
            <Pagination
              current={filter.page}
              total={filtered.length}
              onChange={page => setFilter(p => ({ ...p, page }))}
              defaultPageSize={10}
            />
          </Row>
        </Container>
      </div>
    </Layout>
  )
}
export default DaftarGrantees

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    granteeListOfGrantees(lang: { eq: $language }) {
      page_setting {
        banner_image
        slug
        title
      }
      grantees {
        contract_year
        created_at
        head_of_research_name
        institution
        proposal_title
        research_focus
        rispro_category
      }
    }
  }
`
