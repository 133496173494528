import React from "react"
import { Link, graphql } from "gatsby"
import {
  Row,
  Col,
  Breadcrumb,
  Card,
  Avatar,
  Button,
  Typography,
  Timeline,
} from "antd"
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import moment from "moment"
import { useI18next } from "gatsby-plugin-react-i18next"

import Layout from "../../app/layout"

import Seo from "../../components/seo"
import { ChevronRight } from "../../components/Icon"
import Container from "../../components/Container"
import Masthead from "../../components/Masthead"

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

const { Meta } = Card

const formatDate = v => moment(v).format("DD MMMM YYYY")

const PendaftaranRiset = ({
  data: {
    researchRegistrationScheduling: data = {
      page_setting: {},
      registration: [],
      scheduling: [
        {
          schedule_list: [],
        },
      ],
      video_tutorial: [],
    },
  },
}) => {
  const { t } = useI18next()
  const { Item } = Breadcrumb
  const goToNearest = () => {
    const currDate = new Date().getTime()
    const dateList = data.scheduling.reduce((acc, val) => {
      acc = [
        ...acc,
        ...val.schedule_list.map(d =>
          new Date(d.activity_start_date).getTime()
        ),
      ]

      return acc
    }, [])

    const selectedDate =
      dateList.find(d => d >= currDate) || dateList[dateList.length - 1]

    document.getElementById("anc-" + selectedDate).scrollIntoView({
      behavior: "smooth",
    })
  }
  const today = new Date().getTime()

  return (
    <Layout module_name="research" page_name="research-registration-scheduling">
      <Seo
        title={data.page_setting.title}
        module_name="research"
        page_name="research-registration-scheduling"
      />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Item>
            <Link to="/">{t("Beranda")}</Link>
          </Item>
          <Item>{data.page_setting.title}</Item>
        </Breadcrumb>
      </Container>

      <Masthead
        image={data.page_setting.banner_image}
        title={data.page_setting.title}
        textBottom={t("Lihat Selengkapnya")}
      />

      {data.registration.length > 0 && (
        <div className="section" style={{ overflow: "hidden" }}>
          <Container>
            <Typography.Title>
              {data.page_setting.registration_title}
            </Typography.Title>
            <Swiper
              modules={[Navigation]}
              width={320}
              breakpoints={{
                // when window width is >= 640px
                640: {
                  width: 300,
                  spaceBetween: 20,
                  slidesPerView: 1,
                },
                // when window width is >= 768px
                768: {
                  width: null,
                  spaceBetween: 30,
                  slidesPerView: 2,
                },
              }}
              spaceBetween={20}
              className="slider-registrasi"
              navigation={{
                nextEl: ".next",
                prevEl: ".prev",
                clickable: true,
              }}
            >
              {data.registration.length > 2 && (
                <div className="navigation">
                  <div className="prev">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z"
                        fill="#FF6900"
                      />
                    </svg>
                  </div>
                  <div className="next">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.59 16.59L10 18L16 12L10 6L8.59 7.41L13.17 12L8.59 16.59Z"
                        fill="#FF6900"
                      />
                    </svg>
                  </div>
                </div>
              )}
              {data.registration.map((item, i) => (
                <SwiperSlide key={i}>
                  <Card style={{ height: "100%" }}>
                    <Meta
                      avatar={<Avatar size={80} src={item.logo} />}
                      title={
                        <h3 style={{ fontSize: 16, marginBottom: 0 }}>
                          {item.title}
                        </h3>
                      }
                      description={
                        <>
                          <div style={{ marginBottom: 8 }}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.short_description,
                              }}
                            />
                          </div>

                          <p>
                            <strong>{t("Periode Pendaftaran")}</strong>{" "}
                            {formatDate(item.period_from_month)} -{" "}
                            {formatDate(item.period_to_month)}
                          </p>
                          <Button block size="small" href={item.link_url}>
                            {item.button_text}
                          </Button>
                        </>
                      }
                    />
                  </Card>
                </SwiperSlide>
              ))}
            </Swiper>
          </Container>
        </div>
      )}

      {data.scheduling.length > 0 && (
        <div className="section section-secondary text-medium">
          <Container>
            <div className="text-center">
              <h2 className="section-title">
                {data.page_setting.scheduling_title}
              </h2>
            </div>
            <div className="timeline-container">
              <button
                className="timeline-title timeline-title-red"
                onClick={goToNearest}
                onKeyDown={goToNearest}
                style={{ cursor: "pointer", fontWeight: "bold" }}
              >
                {t("Jadwal Saat Ini")}
              </button>
              {data.scheduling.map((item, i) => (
                <div key={i}>
                  <h4 className="timeline-title" style={{ marginBottom: 0 }}>
                    {item.stage_name}
                  </h4>
                  <Timeline key={i} className="timeline">
                    {(item.schedule_list || []).map((list, index) => {
                      const outdated =
                        moment(list.activity_end_date).unix() * 1000 < today

                      return (
                        <Timeline.Item key={i.toString() + index.toString()}>
                          <div
                            id={
                              "anc-" +
                              new Date(list.activity_start_date).getTime()
                            }
                            className={outdated ? "outdated-timeline" : ""}
                          >
                            <date>
                              {formatDate(list.activity_start_date)} -{" "}
                              {formatDate(list.activity_end_date)}
                            </date>
                            <p className="title">{list.activity_name}</p>
                            <p className="gelombang">{list.tags}</p>
                          </div>
                        </Timeline.Item>
                      )
                    })}
                    {i === data.scheduling.length - 1 && (
                      <Timeline.Item className="last-timeline" />
                    )}
                  </Timeline>
                </div>
              ))}
            </div>
          </Container>
        </div>
      )}

      {data.video_tutorial.length > 0 && (
        <div className="section">
          <Container>
            <div className="text-center" style={{ marginBottom: 24 }}>
              <h3 className="section-title">{t("Video Tutorial")}</h3>
            </div>
            <Row gutter={20}>
              {data.video_tutorial.map((item, i) => (
                <Col span={24} md={8} key={i}>
                  <a
                    href={item.video_url}
                    rel="noreferrer"
                    target="_blank"
                    style={{ marginBottom: 24 }}
                    aria-label={t("Video Tutorial")}
                  >
                    <Card
                      bordered={false}
                      className="card-news"
                      cover={
                        <img alt={item.title} src={item.thumbnail_image} />
                      }
                    >
                      <div className="card-meta">
                        <h3 className="card-title">{item.title}</h3>
                      </div>
                    </Card>
                  </a>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      )}
    </Layout>
  )
}

export default PendaftaranRiset

export const query = graphql`
  query ($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    researchRegistrationScheduling(lang: { eq: $language }) {
      scheduling {
        schedule_list {
          tags
          stage_id
          activity_start_date
          activity_name
          activity_end_date
        }
        stage_name
        publish
      }
      video_tutorial {
        thumbnail_image
        title
        video_url
        publish
      }
      registration {
        button_text
        created_at
        link_url
        order
        logo
        period_from_month
        period_to_month
        period_year
        publish
        short_description
        title
        updated_at
      }
      page_setting {
        banner_image
        created_at
        registration_title
        scheduling_title
        slug
        title
        updated_at
      }
    }
  }
`
