import React from "react"
import { Link, graphql } from "gatsby"
import { Row, Col, Breadcrumb } from "antd"
import ScrollAnimation from "react-animate-on-scroll"
import { useI18next } from "gatsby-plugin-react-i18next"

import Layout from "../../app/layout"
import Seo from "../../components/seo"

import Container from "../../components/Container"
import CardNews from "../../components/CardNews"
import Newsletter from "../../components/Newsletter"
import FeaturedNews from "../../components/FeaturedNews"
import { ChevronRight } from "../../components/Icon"

const KontribusiPage = ({
  data: {
    awardeeContributions: data = {
      page_setting: {},
      news: [],
      highlighted_news: [],
    },
  },
}) => {
  let items = [...data.news]

  const { Item } = Breadcrumb
  const { t } = useI18next()

  if (items.length > 2) {
    items.splice(2, 0, { component: <Newsletter /> })
  } else {
    items = [...data.news, { component: <Newsletter /> }]
  }

  return (
    <Layout>
      <Seo title={data.page_setting.title} />
      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Item>
            <Link to="/">{t("Beranda")}</Link>
          </Item>
          <Item>{data.page_setting.title}</Item>
        </Breadcrumb>
      </Container>
      <div className="section text-medium">
        <Container>
          <Row gutter={40} style={{ margin: "0 -15px" }}>
            <Col span={24} md={12} style={{ padding: "0 15px" }}>
              <h1 className="section-title">{data.page_setting.title}</h1>
            </Col>
            <Col span={24} md={12} style={{ padding: "0 15px" }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.page_setting.description,
                }}
              />
            </Col>
          </Row>
          <hr style={{ marginTop: 24, marginBottom: 40 }} />
          {data.highlighted_news.content !== null && (
            <FeaturedNews
              image={data.highlighted_news.image}
              title={data.highlighted_news.title}
              excerpt={data.highlighted_news.content}
              url={`/informasi/berita/${data.highlighted_news.slug}?cat=/awardee/kontribusi`}
            />
          )}

          <Row gutter={30} style={{ margin: "24px -15px 0 -15px" }}>
            {items.map((item, i) => {
              const { component, title, image, slug } = item

              if (component) {
                return (
                  <Col span={24} md={8} key={i} style={{ padding: "0 15px" }}>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                      <Newsletter />
                    </ScrollAnimation>
                  </Col>
                )
              }

              return (
                <Col span={24} md={8} key={i} style={{ padding: "0 15px" }}>
                  <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                    <CardNews
                      title={title}
                      image={image}
                      url={`/informasi/berita/${slug}?cat=/awardee/kontribusi`}
                    />
                  </ScrollAnimation>
                </Col>
              )
            })}
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query awardeeContributionsQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    awardeeContributions(lang: { eq: $language }) {
      page_setting {
        description
        slug
        title
      }
      news {
        content
        image
        is_highlight
        news_category
        photographer_name
        slug
        title
        writer_name
      }
      highlighted_news {
        content
        image
        news_category
        photographer_name
        publish
        slug
        title
        writer_name
      }
    }
  }
`

export default KontribusiPage
