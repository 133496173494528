import React, { useState, useEffect, useCallback } from "react"
import { Link, useI18next } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"
import ScrollAnimation from "react-animate-on-scroll"
import { Row, Col, Breadcrumb, Button } from "antd"
import Layout from "../../app/layout"
import Seo from "../../components/seo"
import Container from "../../components/Container"
import { ChevronRight } from "../../components/Icon"

function useMedia(queries, values, defaultValue) {
  const [mediaQueryLists, setMediaQueryLists] = useState([])
  const [value, setValue] = useState(defaultValue)

  const getValue = useCallback(
    media => {
      const index = media.findIndex(mql => mql.matches)
      return typeof values[index] !== "undefined" ? values[index] : defaultValue
    },
    [values, defaultValue]
  )

  useEffect(() => {
    const query = queries.map(q => window.matchMedia(q))
    setMediaQueryLists(query)
    setValue(getValue(query))
  }, [queries, getValue])

  useEffect(() => {
    const handler = () => setValue(getValue(mediaQueryLists))
    mediaQueryLists.forEach(mql => mql.addListener(handler))
    return () => mediaQueryLists.forEach(mql => mql.removeListener(handler))
  }, [mediaQueryLists, getValue])

  return value
}

const PenghargaanPage = ({
  data: {
    allAboutAwards: { nodes: data = [] },
  },
}) => {
  const limit = useMedia(["(min-width: 768px)"], [9], 8)
  const [more, setMore] = useState(0)
  const { t } = useI18next()
  const { Item } = Breadcrumb
  const lists = data.slice(0, (more + 1) * limit)
  return (
    <Layout module_name="about" page_name="awards">
      <Seo title={t("Penghargaan")} module_name="about" page_name="awards" />
      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Item>
            <Link to="/">{t("Beranda")}</Link>
          </Item>
          <Item>{t("Penghargaan")}</Item>
        </Breadcrumb>
      </Container>

      <div className="section text-medium section-padding-top-less">
        <Container>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h1 className="section-title-huge">{t("Penghargaan")}</h1>
          </ScrollAnimation>
          <Row gutter={30} style={{ marginTop: 24, justifyContent: "center" }}>
            {lists.map((item, i) => (
              <Col span={24} xs={12} md={8} key={i}>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <div
                    style={{
                      height: 240,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={item.image}
                      alt={item.title}
                      style={{
                        height: 240,
                        width: "100%",
                        objectFit: "contain",
                      }}
                      width="auto"
                    />
                  </div>
                  <h5
                    style={{ fontSize: 16, marginTop: 20 }}
                    className="text-center"
                  >
                    {item.title}
                  </h5>
                </ScrollAnimation>
              </Col>
            ))}
          </Row>
          {lists.length !== data.length && data.length > limit && (
            <Row justify="center">
              <Button onClick={() => setMore(more + 1)} type="link">
                {t("...Muat Lebih")}
              </Button>
            </Row>
          )}
        </Container>
      </div>
    </Layout>
  )
}

export default PenghargaanPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "index", "page"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    allAboutAwards(filter: { lang: { eq: $language } }) {
      nodes {
        title
        slug
        image
      }
    }
  }
`
