import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Link, graphql } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Row, Col, Breadcrumb, Button } from "antd"

import Layout from "../../app/layout"
import Seo from "../../components/seo"
import Container from "../../components/Container"
import { ChevronRight } from "../../components/Icon"
import CardWithIcon from "../../components/CardWithIcon"
import {
  FacebookOutlined,
  XOutlined,
  TiktokOutlined,
  YoutubeOutlined,
  InstagramOutlined,
  LinkedInOutlined,
} from "../../components/Icon"

import "./contact.less"

const ContactPage = ({ data: { contacts: data } }) => {
  const { Item } = Breadcrumb
  const { t } = useI18next()
  const SocialLinkData = [
    {
      icon: <FacebookOutlined />,
      url: "https://www.facebook.com/LPDPKemenkeu/",
    },
    {
      icon: <XOutlined />,
      url: "https://x.com/LPDP_RI",
    },
    {
      icon: <TiktokOutlined />,
      url: "https://www.tiktok.com/@lpdp_ri",
    },
    {
      icon: <YoutubeOutlined />,
      url: "https://www.youtube.com/channel/UCLWpsuqDrJ19mTZVLA_ot0w",
    },
    {
      icon: <InstagramOutlined />,
      url: "https://www.instagram.com/LPDP_RI",
    },
    {
      icon: <LinkedInOutlined />,
      url: "https://www.linkedin.com/company/lembaga-pengelola-dana-pendidikan/",
    },
  ]

  return (
    <Layout module_name="information" page_name="contact-us">
      <Seo
        title={t("Hubungi Kami")}
        module_name="information"
        page_name="contact-us"
      />
      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Item>
            <Link to="/">{t("Beranda")}</Link>
          </Item>
          <Item>{t("Hubungi Kami")}</Item>
        </Breadcrumb>
      </Container>

      <div
        className="section section-secondary text-medium"
        style={{ marginTop: 30 }}
      >
        <div className="section hide-mobile"></div>
      </div>

      <div className="section">
        <Container>
          <img
            src={data.header_banner}
            alt="Hubungi Kami"
            className="image-contact"
            width="100%"
          />

          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <Row gutter={20}>
              <Col span={24} md={8} style={{ marginBottom: 16 }}>
                <CardWithIcon theme="fill">
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.1667 3.33301C11.35 3.33301 5 9.68301 5 17.4997C5 25.3163 11.35 31.6663 19.1667 31.6663H20V36.6663C28.1 32.7663 33.3333 24.9997 33.3333 17.4997C33.3333 9.68301 26.9833 3.33301 19.1667 3.33301ZM20.8333 27.4997H17.5V24.1663H20.8333V27.4997ZM20.8333 21.6663H17.5C17.5 16.2497 22.5 16.6663 22.5 13.333C22.5 11.4997 21 9.99967 19.1667 9.99967C17.3333 9.99967 15.8333 11.4997 15.8333 13.333H12.5C12.5 9.64967 15.4833 6.66634 19.1667 6.66634C22.85 6.66634 25.8333 9.64967 25.8333 13.333C25.8333 17.4997 20.8333 17.9163 20.8333 21.6663Z"
                      fill="white"
                    />
                  </svg>

                  <h3>{data.title}</h3>
                  <div
                    dangerouslySetInnerHTML={{ __html: data.short_description }}
                  />
                  <Button
                    className="btn-outline-white"
                    size="large"
                    style={{ width: "100%" }}
                  >
                    <a
                      href={data.faq_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("Lihat FAQ")}
                    </a>
                  </Button>
                </CardWithIcon>
              </Col>
              <Col span={24} md={8} style={{ marginBottom: 16 }}>
                <CardWithIcon>
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.0333 17.9833C13.4333 22.7 17.3 26.55 22.0167 28.9667L25.6833 25.3C26.1333 24.85 26.8 24.7 27.3833 24.9C29.25 25.5167 31.2667 25.85 33.3333 25.85C34.25 25.85 35 26.6 35 27.5167V33.3333C35 34.25 34.25 35 33.3333 35C17.6833 35 5 22.3167 5 6.66667C5 5.75 5.75 5 6.66667 5H12.5C13.4167 5 14.1667 5.75 14.1667 6.66667C14.1667 8.75 14.5 10.75 15.1167 12.6167C15.3 13.2 15.1667 13.85 14.7 14.3167L11.0333 17.9833Z"
                      fill="#AF272F"
                    />
                  </svg>

                  <h3>{t("Call Center")}</h3>
                  <div
                    dangerouslySetInnerHTML={{ __html: data.phone_number }}
                  />
                  <br />
                  <Button
                    size="large"
                    style={{ width: "100%" }}
                    // onClick={() => {
                    //   Cso()
                    // }}
                  >
                    <Link to="/cso">Customer Service Online</Link>
                  </Button>
                </CardWithIcon>
              </Col>
              <Col span={24} md={8} style={{ marginBottom: 16 }}>
                <CardWithIcon>
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.0002 3.33301C13.5502 3.33301 8.3335 8.54967 8.3335 14.9997C8.3335 23.7497 20.0002 36.6663 20.0002 36.6663C20.0002 36.6663 31.6668 23.7497 31.6668 14.9997C31.6668 8.54967 26.4502 3.33301 20.0002 3.33301ZM20.0002 19.1663C17.7002 19.1663 15.8335 17.2997 15.8335 14.9997C15.8335 12.6997 17.7002 10.833 20.0002 10.833C22.3002 10.833 24.1668 12.6997 24.1668 14.9997C24.1668 17.2997 22.3002 19.1663 20.0002 19.1663Z"
                      fill="#AF272F"
                    />
                  </svg>

                  <h3>{t("Alamat Kantor")}</h3>
                  <div dangerouslySetInnerHTML={{ __html: data.address }} />
                  <Button type="link">
                    <a
                      href={data.location_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("Lihat Lokasi")}
                    </a>
                  </Button>
                </CardWithIcon>
              </Col>
            </Row>

            <hr />
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <Row justify="space-between" className="contact-bottom">
              <Col flex="none">
                <p>{t("Atau hubungi dan follow kami di media sosial")}</p>
              </Col>
              <Col flex="none">
                <Row align="center" gutter={20}>
                  {SocialLinkData.map((item, i) => (
                    <Col flex="auto" key={i}>
                      <a
                        href={item.url}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {item.icon}
                      </a>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </ScrollAnimation>
        </Container>
      </div>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query ContactsQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contacts(lang: { eq: $language }) {
      address
      created_at
      faq_url
      header_banner
      location_url
      phone_number
      short_description
      title
    }
  }
`
