import React from "react"
import { Link, graphql } from "gatsby"
import { Row, Col, Typography, Breadcrumb, Space, Divider } from "antd"
import { useI18next } from "gatsby-plugin-react-i18next"
import Layout from "../app/layout"
import Seo from "../components/seo"
import Container from "../components/Container"
import { ChevronRight } from "../components/Icon"

const toArray = data => Object.keys(data).map(d => data[d])

const PetaSitus = ({
  data: {
    allSiteMaps: { nodes: data = [] },
  },
}) => {
  const { Text, Title } = Typography
  const { Item } = Breadcrumb
  const { t } = useI18next()
  const {
    about,
    investment,
    awardee,
    grantee,
    information,
    research,
    scholarship,
  } = data[0]

  return (
    <Layout>
      <Seo title="Peta Situs" />
      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Item>
            <Link to="/">{t("Beranda")}</Link>
          </Item>
          <Item>Peta Situs</Item>
        </Breadcrumb>
      </Container>
      <Container>
        <Title style={{ margin: "50px 0px 70px 0px" }}>
          {t("Peta Situs")}
        </Title>
        <Row gutter={30}>
          <Col xs={24} sm={6}>
            <Text
              style={{ marginBottom: 20, display: "block" }}
              strong
            >
              {t("TENTANG")}
            </Text>
          </Col>
          <Col xs={24} sm={18}>
            <Row>
              {toArray(about).map((d, i) => (
                <Col xs={24} sm={6} key={i}>
                  <Space direction="vertical" style={{ marginBottom: 15 }}>
                    <Link to={d.url}>{d.title}</Link>
                  </Space>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row gutter={30}>
          <Col xs={24} sm={6}>
            <Text
              style={{ marginBottom: 20, display: "block" }}
              strong
            >
              {t("INVESTASI")}
            </Text>
          </Col>
          <Col xs={24} sm={18}>
            <Row>
              {toArray(investment).map((d, i) => (
                <Col xs={24} sm={6} key={i}>
                  <Space direction="vertical" style={{ marginBottom: 15 }}>
                    <Link to={d.url}>{d.title}</Link>
                  </Space>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row gutter={30}>
          <Col xs={24} sm={6}>
            <Text
              style={{ marginBottom: 20, display: "block" }}
              strong
            >
              {t("BEASISWA")}
            </Text>
          </Col>
          <Col xs={24} sm={18}>
            <Row>
              {toArray(scholarship).map((d, i) => {
                if (Array.isArray(d)) {
                  return d.map((dd, ii) => (
                    <Col xs={24} sm={6} key={ii + "cc"}>
                      <Space direction="vertical" style={{ marginBottom: 15 }}>
                        <Link to={dd.url}>{dd.title}</Link>
                      </Space>
                    </Col>
                  ))
                }
                return (
                  <Col xs={24} sm={6} key={i}>
                    <Space direction="vertical" style={{ marginBottom: 15 }}>
                      <Link to={d.url}>{d.title}</Link>
                    </Space>
                  </Col>
                )
              })}
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row gutter={30}>
          <Col xs={24} sm={6}>
            <Text
              style={{ marginBottom: 20, display: "block" }}
              strong
            >
              {t("AWARDEE")}
            </Text>
          </Col>
          <Col xs={24} sm={18}>
            <Row>
              {toArray(awardee).map((d, i) => (
                <Col xs={24} sm={6} key={i}>
                  <Space direction="vertical" style={{ marginBottom: 15 }}>
                    <Link to={d.url}>{d.title}</Link>
                  </Space>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row gutter={30}>
          <Col xs={24} sm={6}>
            <Text
              style={{ marginBottom: 20, display: "block" }}
              strong
            >
              {t("RISET")}
            </Text>
          </Col>
          <Col xs={24} sm={18}>
            <Row>
              {toArray(research).map((d, i) => {
                if (Array.isArray(d)) {
                  return d.map((dd, ii) => (
                    <Col xs={24} sm={6} key={ii + "cc"}>
                      <Space direction="vertical" style={{ marginBottom: 15 }}>
                        <Link to={dd.url}>{dd.title}</Link>
                      </Space>
                    </Col>
                  ))
                }
                return (
                  <Col xs={24} sm={6} key={i}>
                    <Space direction="vertical" style={{ marginBottom: 15 }}>
                      <Link to={d.url}>{d.title}</Link>
                    </Space>
                  </Col>
                )
              })}
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row gutter={30}>
          <Col xs={24} sm={6}>
            <Text
              style={{ marginBottom: 20, display: "block" }}
              strong
            >
              {t("GRANTEES")}
            </Text>
          </Col>
          <Col xs={24} sm={18}>
            <Row>
              {toArray(grantee).map((d, i) => (
                <Col xs={24} sm={6} key={i}>
                  <Space direction="vertical" style={{ marginBottom: 15 }}>
                    <Link to={d.url}>{d.title}</Link>
                  </Space>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Divider />
        <Row gutter={30}>
          <Col xs={24} sm={6}>
            <Text
              style={{ marginBottom: 20, display: "block" }}
              strong
            >
              {t("INFORMASI")}
            </Text>
          </Col>
          <Col xs={24} sm={18}>
            <Row>
              {toArray(information).map((d, i) => (
                <Col xs={24} sm={6} key={i}>
                  <Space direction="vertical" style={{ marginBottom: 15 }}>
                    <Link to={d.url}>{d.title}</Link>
                  </Space>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Divider />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query petaSitusQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allSiteMaps(filter: { lang: { eq: $language } }) {
      nodes {
        scholarship {
          affirmation {
            url
            title
          }
          collaboration {
            url
            title
          }
          general {
            url
            title
          }
          public_policy {
            url
            title
          }
          registration_scheduling {
            url
            title
          }
          targeted {
            url
            title
          }
        }
        research {
          public_policy {
            title
            url
          }
          registration_scheduling {
            title
            url
          }
          rispro_collaboration {
            title
            url
          }
          rispro_competition {
            url
            title
          }
          rispro_invitation {
            title
            url
          }
          rispro_mandatory {
            title
            url
          }
        }
        investment {
          donation {
            title
            url
          }
          instrument_portfolio {
            title
            url
          }
          scheme_policy {
            title
            url
          }
        }
        information {
          career {
            title
            url
          }
          contact_us {
            title
            url
          }
          faq {
            title
            url
          }
          financial_report {
            title
            url
          }
          infographics {
            title
            url
          }
          live {
            title
            url
          }
          news {
            title
            url
          }
          performance_report {
            title
            url
          }
          yearly_report {
            title
            url
          }
          other_report {
            title
            url
          }
          public_information {
            title
            url
          }
          cso {
            title
            url
          }
        }
        grantee {
          inspiration_story {
            title
            url
          }
          lisf_of_grantee {
            title
            url
          }
          rispro_tech_brief {
            title
            url
          }
        }
        awardee {
          contribution {
            title
            url
          }
          departure_preparation_generation {
            title
            url
          }
          departure_preparation_policy {
            title
            url
          }
          grantee_featured_generation {
            title
            url
          }
          grantee_guide {
            title
            url
          }
          grantee_mata_garuda {
            title
            url
          }
          language_enrichment {
            title
            url
          }
          serba_serbi {
            title
            url
          }
        }
        about {
          award {
            title
            url
          }
          management {
            title
            url
          }
          organization_structure {
            title
            url
          }
          selayang_pandang {
            title
            url
          }
          testimony {
            title
            url
          }
          vision_mission {
            title
            url
          }
        }
      }
    }
  }
`

export default PetaSitus
