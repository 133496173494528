import React from "react"
import { graphql, Link } from "gatsby"
import { ChevronRight } from "../../components/Icon"
import ScrollAnimation from "react-animate-on-scroll"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Row, Col, Breadcrumb, Button } from "antd"

import Layout from "../../app/layout"
import Seo from "../../components/seo"
import Laporan from "../../components/Laporan"
import Container from "../../components/Container"

const Infografik = ({
  data: {
    allInformationReportInfographic: { nodes: data = [] },
    reportSettings: { infografis: settings = {} },
  },
}) => {
  const { Item } = Breadcrumb
  const { t } = useI18next()

  return (
    <Layout module_name="information" page_name="infografis">
      <Seo
        title={t("Infografik")}
        module_name="information"
        page_name="infografis"
      />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Item>
            <Link to="/">{t("Beranda")}</Link>
          </Item>
          <Item>{settings.page_title}</Item>
        </Breadcrumb>
      </Container>

      <div className="section text-medium section-padding-top-less">
        <Container>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h1 className="section-title-huge">{settings.page_title}</h1>
          </ScrollAnimation>
          <Row gutter={40} style={{ margin: "0 -15px" }}>
            {data.map((item, i) => (
              <Col span={24} md={6} key={i} style={{ padding: "0 15px" }}>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <Laporan
                    title={item.title}
                    image={item.thumbnail_image}
                    fileurl={item.file}
                  />
                </ScrollAnimation>
              </Col>
            ))}
          </Row>
          {data.length > 12 && (
            <div className="text-center" style={{ marginTop: 30 }}>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <Button
                  type="link"
                  style={{ textTransform: "capitalize", direction: "ltr" }}
                >
                  {t("...Muat Lebih")}
                </Button>
              </ScrollAnimation>
            </div>
          )}
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query allInformationReportInfographicQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allInformationReportInfographic(filter: { lang: { eq: $language } }) {
      nodes {
        thumbnail_image
        title
        file
        created_at
      }
    }
    reportSettings(lang: { eq: $language }) {
      infografis {
        page_title
        page_name
        slug
      }
    }
  }
`

export default Infografik
