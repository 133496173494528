import React from "react"
import { Link, graphql } from "gatsby"
import moment from "moment"
import { Row, Col, Breadcrumb } from "antd"
import ScrollAnimation from "react-animate-on-scroll"
import { useI18next } from "gatsby-plugin-react-i18next"
import Layout from "../../app/layout"
import Seo from "../../components/seo"
import Container from "../../components/Container"
import { ChevronRight } from "../../components/Icon"

const LivePage = ({ data: { lives: data }, pageContext: { language } }) => {
  const { t } = useI18next()
  const { Item } = Breadcrumb
  return (
    <Layout module_name="information" page_name="live">
      <Seo title="Live" module_name="information" page_name="live" />
      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Item>
            <Link to="/">{t("Beranda")}</Link>
          </Item>
          <Item>{data.title}</Item>
        </Breadcrumb>
      </Container>

      <div className="section text-medium section-padding-top-less">
        <Container>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h1 className="section-title-huge">{data.title}</h1>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <iframe
              width="100%"
              height="600"
              src={"https://www.youtube.com/embed/" + data.video_url}
              title={data.broadcast_platform}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ marginBottom: "-150px" }}
            ></iframe>
          </ScrollAnimation>
        </Container>
      </div>

      <div className="section section-secondary text-medium">
        <Container>
          <div className="section" style={{ paddingBottom: 0 }}>
            <Row
              gutter={40}
              justify="space-between"
              style={{ margin: "0 -15px" }}
            >
              <Col span={24} md={14} style={{ padding: "0 15px" }}>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <div dangerouslySetInnerHTML={{ __html: data.content }} />
                </ScrollAnimation>
              </Col>

              <Col span={24} md={7} style={{ padding: "0 15px" }}>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <p className="label" style={{ marginBottom: 0 }}>
                    {t("Waktu")}{" "}
                  </p>
                  <p className="text-highlight" style={{ marginBottom: 0 }}>
                    {moment(data.event_date)
                      .locale(language)
                      .format("dddd, DD MMMM YYYY")}
                  </p>
                  <p>
                    {data.event_time} {t("s.d. selesai")}
                  </p>

                  <p className="label" style={{ marginBottom: 0 }}>
                    {t("Disiarkan Melalui")}{" "}
                  </p>
                  <p className="text-highlight" style={{ marginBottom: 0 }}>
                    {data.broadcast_platform}
                  </p>
                </ScrollAnimation>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default LivePage

export const query = graphql`
  query LivesQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    lives(lang: { eq: $language }) {
      content
      event_date
      event_time
      title
      updated_at
      video_url
      broadcast_platform
    }
  }
`
