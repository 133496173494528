import React from "react"
import { Link } from "gatsby"
import { Col, Row, Button } from "antd"
import SEO from "../components/seo"
import OwliLarge from "../components/Image/OwliLarge"
import {
  FacebookOutlined,
  XOutlined,
  TiktokOutlined,
  YoutubeOutlined,
  InstagramOutlined,
  LinkedInOutlined,
} from "../components/Icon"

import "../theme/cso.less"

const cso = () => {
  const SocialLinkData = [
    {
      icon: <FacebookOutlined />,
      url: "https://www.facebook.com/LPDPKemenkeu/",
    },
    {
      icon: <XOutlined />,
      url: "https://x.com/LPDP_RI",
    },
    {
      icon: <TiktokOutlined />,
      url: "https://www.tiktok.com/@lpdp_ri",
    },
    {
      icon: <YoutubeOutlined />,
      url: "https://www.youtube.com/channel/UCLWpsuqDrJ19mTZVLA_ot0w",
    },
    {
      icon: <InstagramOutlined />,
      url: "https://www.instagram.com/LPDP_RI",
    },
    {
      icon: <LinkedInOutlined />,
      url: "https://www.linkedin.com/company/lembaga-pengelola-dana-pendidikan/",
    },
  ]

  return (
    <section className="cso-wrapper">
      <SEO title="CS OWLI" />
      <div
        style={{
          width: "100%",
          paddingRight: "15px",
          paddingLeft: "15px",
          marginRight: "auto",
          marginLeft: "auto",
          maxWidth: "1180px",
          height: "100%",
        }}
      >
        <div
          style={{
            paddingTop: "24px",
            paddingBottom: "24px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Row justify="space-between" gutter={[24, 24]}>
            <Col
              span={12}
              gutter={24}
              order={1}
              xs={{ order: 2, span: 24 }}
              lg={{ order: 1, span: 12 }}
            >
              <Row gutter={[24, 24]} justify="space-between">
                <Col
                  span={24}
                  className="owl-image"
                  xs={{ span: 12, offset: 6 }}
                  lg={{ span: 24, offset: 0 }}
                >
                  <OwliLarge />
                </Col>
                <Col span={24}>
                  <Row justify="space-between" gutter={[16, { xs: 8, lg: 16 }]}>
                    <Col
                      xs={{ flex: "auto" }}
                      lg={{ flex: 1 }}
                      className="link-item"
                    >
                      <Button>
                        <Link to="/beasiswa/pendaftaran-beasiswa/">
                          Mendaftar Beasiswa
                        </Link>
                      </Button>
                    </Col>
                    <Col
                      xs={{ flex: "auto" }}
                      lg={{ flex: 1 }}
                      className="link-item"
                    >
                      <Button>
                        <Link to="/riset/kebijakan-rispros-umum/">
                          Informasi Pendanaan Riset
                        </Link>
                      </Button>
                    </Col>
                    <Col
                      xs={{ flex: "auto" }}
                      lg={{ flex: 1 }}
                      className="link-item"
                    >
                      <Button>
                        <a
                          href="https://bantuan.lpdp.kemenkeu.go.id/kb"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          FAQ
                        </a>
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col
              span={10}
              order={2}
              xs={{ order: 1, span: 24 }}
              lg={{ order: 2, span: 10 }}
              className="iframe"
            >
              <div
                style={{
                  height: "100%",
                  backgroundColor: "#eee",
                }}
              >
                <iframe
                  src="https://chat.sociomile.com/livechat/658d96fa801ddf5bd44aba3b"
                  width={"100%"}
                  height={"100%"}
                ></iframe>
              </div>
            </Col>
          </Row>

          <Row
            justify="space-between"
            style={{ paddingTop: "12px" }}
            gutter={[16, 8]}
            align="middle"
          >
            <Col
              order={1}
              xs={{ order: 2, span: 24 }}
              lg={{ order: 1, span: 12 }}
              className="copyright"
            >
              © 2025 LPDP. All rights reserved.
            </Col>
            <Col
              order={2}
              xs={{ order: 1, span: 24 }}
              lg={{ order: 2, span: 12 }}
              gutter={16}
            >
              <Row align="middle" justify="center" gutter={6}>
                {SocialLinkData.map((item, i) => (
                  <Col span={2} key={i} className="social-item">
                    <a
                      href={item.url}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {item.icon}
                    </a>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  )
}

export default cso
