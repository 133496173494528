import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button, Card, Modal } from "antd"

import "./CardTeam.less"

const CardTeam = ({ image, status, nama, jabatan, deskripsi, btnText }) => {
  const [visible, setVisible] = useState(false)

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <Card cover={<img alt={nama} src={image} />}>
      <div>{status}</div>
      <div>{nama}</div>
      <div>{jabatan}</div>

      {deskripsi && (
        <>
          <Button type="link" onClick={showModal}>
            {btnText}
          </Button>

          <Modal
            visible={visible}
            onCancel={handleCancel}
            footer={null}
            title={nama}
          >
            <div>
              <strong>{jabatan}</strong>
            </div>
            <div dangerouslySetInnerHTML={{ __html: deskripsi }} />
          </Modal>
        </>
      )}
    </Card>
  )
}

CardTeam.propTypes = {
  image: PropTypes.string,
  nama: PropTypes.string,
  status: PropTypes.string,
  deskripsi: PropTypes.string,
  jabatan: PropTypes.string,
  btnText: PropTypes.string,
}

export default CardTeam
