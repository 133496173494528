import React from "react"
import { graphql, Link as GatsbyLink } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Row, Col, Breadcrumb } from "antd"
import Layout from "../../app/layout"
import Seo from "../../components/seo"
import Container from "../../components/Container"
import { ChevronRight } from "../../components/Icon"
import NavigasiNews from "../../components/NavigasiNews"

const SkemaKebijakan = ({
  data: {
    invesmentSchemaPolicies: data = {
      page_contents: [],
      page_setting: {},
    },
  },
}) => {
  const { t } = useI18next()
  const { Item } = Breadcrumb
  return (
    <Layout module_name="investment" page_name="scheme-policy">
      <Seo
        title={data.page_setting.title}
        module_name="investment"
        page_name="scheme-policy"
      />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Item>
            <GatsbyLink to="/">{t("Beranda")}</GatsbyLink>
          </Item>
          <Item>{data.page_setting.title}</Item>
        </Breadcrumb>
      </Container>

      <div className="section text-medium">
        <Container>
          <Row justify="space-between">
            <Col span={24} md={{ order: 1, span: 17 }}>
              <h1 className="section-title">{data.page_setting.title}</h1>
              <article className="article">
                {data.page_contents.map((d, i) => (
                  <div key={i}>
                    <h4 id={"anchor-" + i}>{d.title}</h4>
                    <div dangerouslySetInnerHTML={{ __html: d.description }} />
                  </div>
                ))}
              </article>
            </Col>
            <Col span={24} md={6}>
              <div offsetTop={80} className="sticky-sidebar">
                <div className="hide-mobile">
                  <NavigasiNews data={data.page_contents} limit={10} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default SkemaKebijakan

export const query = graphql`
  query invesmentSchemaPoliciesQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    invesmentSchemaPolicies(lang: { eq: $language }) {
      page_contents {
        created_at
        description
        title
        updated_at
      }
      page_setting {
        created_at
        slug
        title
        updated_at
      }
    }
  }
`
