import React, { useState } from "react"
import { ChevronDown } from "./Icon"
import { Anchor, Button } from "antd"
import { useI18next, Trans } from "gatsby-plugin-react-i18next"
import _orderBy from "lodash/orderBy"

const NavigasiNews = ({ data, limit }) => {
  const { Link } = Anchor
  const { t } = useI18next()
  const [showMore, setShowMore] = useState(limit)
  const orderedData = _orderBy(data, ["order"], "asc")

  return (
    <>
      <h4 className="section-title-small">{t("Navigasi")}</h4>
      <Anchor affix={false} offsetTop={80} className="anchor-menu">
        {orderedData.slice(0, showMore).map((d, i) => {
          return (
            <Link
              key={i}
              href={"#anchor-" + i}
              title={d.content_title || d.title}
            />
          )
        })}
      </Anchor>
      {data.length === showMore && data.length > limit && (
        <Button
          onClick={() => setShowMore(limit)}
          type="link"
          style={{ marginTop: 15, marginLeft: 15 }}
          icon={
            <ChevronDown
              style={{
                color: "#FF6",
                position: "relative",
                transform: "rotate(180deg)",
                top: -5,
              }}
              twoToneColor="#FF6900"
            />
          }
        >
          <Trans>TUTUP</Trans>
        </Button>
      )}
      {data.length !== showMore && data.length > limit && (
        <Button
          onClick={() => setShowMore(data.length)}
          type="link"
          style={{ marginTop: 15, marginLeft: 15 }}
          icon={
            <ChevronDown
              style={{
                marginTop: 5,
                color: "#FF6",
                position: "relative",
                top: 1,
              }}
              twoToneColor="#FF6900"
            />
          }
        >
          <Trans>SELEBIHNYA</Trans>
        </Button>
      )}
    </>
  )
}

export default NavigasiNews
