import React from "react"
import { Link as GatsbyLink, graphql } from "gatsby"
import Seo from "../../components/seo"
import Layout from "../../app/layout"

import { Row, Col, Breadcrumb, Button } from "antd"
import ScrollAnimation from "react-animate-on-scroll"
import _orderBy from "lodash/orderBy"

import Container from "../../components/Container"
import { ChevronRight } from "../../components/Icon"
import LinkUnduhan from "../../components/LinkUnduhan"
import NavigasiNews from "../../components/NavigasiNews"
import PageNotFound from "../404"
import { useI18next } from "gatsby-plugin-react-i18next"

const BeasiswaSingle = ({
  pageContext: {
    data: dataall = {
      id: {
        content_files: [],
        content_items: [],
      },
      en: {
        content_files: [],
        content_items: [],
      },
    },
    language = "id",
  },
}) => {
  const { Item } = Breadcrumb
  const { t } = useI18next()
  const data = dataall[language]
  if (!data) return <PageNotFound />
  const orderedContents = _orderBy(data.content_items, ["order"], "asc")
  return (
    <Layout>
      <Seo title={data.header_title} />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Item>
            <GatsbyLink to="/">{t("Beranda")}</GatsbyLink>
          </Item>
          <Item>{data.submenu_name}</Item>
          <Item>{data.header_title}</Item>
        </Breadcrumb>
      </Container>

      <div className="section text-medium section-padding-top-less">
        <Container>
          <Row justify="space-between">
            <Col span={24} md={{ order: 1, span: 17 }}>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <h1 className="section-title-huge">{data.header_title}</h1>
                <article className="article">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.header_description,
                    }}
                  />
                  {orderedContents.map((d, i) => (
                    <div key={i}>
                      <h4 id={"anchor-" + i}>{d.content_title}</h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: d.content_description,
                        }}
                      />
                    </div>
                  ))}
                </article>
              </ScrollAnimation>
            </Col>
            <Col span={24} md={6}>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <div className="sticky-sidebar">
                  <div className="hide-mobile">
                    <NavigasiNews data={data.content_items} limit={10} />
                    <hr style={{ margin: "30px 0 15px 0px" }} />

                    <Button
                      block
                      size="small"
                      target="_blank"
                      href={data.registration_url}
                      style={{ marginBottom: 15 }}
                    >
                      {t("DAFTAR")}
                    </Button>
                  </div>
                  {data.content_files.length > 0 && (
                    <LinkUnduhan
                      Links={data.content_files.map(d => ({
                        header_title: d.title,
                        items: d.file_items.map(dd => ({
                          file: dd.file,
                          item_title: dd.file_title,
                        })),
                      }))}
                    />
                  )}
                </div>
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default BeasiswaSingle

export const query = graphql`
  query beasiswaSingleQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
