import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import { Row, Col, Breadcrumb, Button } from "antd"
import ScrollAnimation from "react-animate-on-scroll"
import { useI18next } from "gatsby-plugin-react-i18next"

import Layout from "../../app/layout"
import Seo from "../../components/seo"
import Laporan from "../../components/Laporan"
import Container from "../../components/Container"
import { ChevronRight } from "../../components/Icon"

const LapKinPage = ({
  data: {
    allLaporanPerformances: { nodes: data },
    reportSettings: { laporan_kinerja: settings = {} },
  },
}) => {
  const { Item } = Breadcrumb
  const [more, setMore] = useState(0)
  const { t } = useI18next()
  const lists = [...data].slice(0, (more + 1) * 12)

  return (
    <Layout page_name="laporan-kinerja" module_name="information">
      <Seo
        title="Laporan Kinerja"
        page_name="laporan-kinerja"
        module_name="information"
      />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Item>
            <Link to="/">{t("Beranda")}</Link>
          </Item>
          <Item>{settings.page_title}</Item>
        </Breadcrumb>
      </Container>

      <div className="section text-medium section-padding-top-less">
        <Container>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h1 className="section-title-huge">{settings.page_title}</h1>
          </ScrollAnimation>
          <Row gutter={40} style={{ margin: "0 -15px" }}>
            {lists.map((item, i) => (
              <Col span={24} md={6} key={i} style={{ padding: "0 15px" }}>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <Laporan
                    title={item.title}
                    image={item.thumbnail_image}
                    fileurl={item.file}
                  />
                </ScrollAnimation>
              </Col>
            ))}
          </Row>

          {lists.length !== data.length && data.length > 12 && (
            <div className="text-center" style={{ marginTop: 30 }}>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <Button
                  type="link"
                  onClick={() => setMore(more + 1)}
                  style={{ textTransform: "capitalize", direction: "ltr" }}
                >
                  {t("...Muat Lebih")}
                </Button>
              </ScrollAnimation>
            </div>
          )}
        </Container>
      </div>
    </Layout>
  )
}

export default LapKinPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allLaporanPerformances(filter: { lang: { eq: $language } }) {
      nodes {
        thumbnail_image
        title
        file
      }
    }
    reportSettings(lang: { eq: $language }) {
      laporan_kinerja {
        page_title
        page_name
        slug
      }
    }
  }
`
