import React, { useState, useMemo } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Link, graphql } from "gatsby"
import { Row, Col, Breadcrumb, Button, Input } from "antd"
import { useI18next } from "gatsby-plugin-react-i18next"

import Layout from "../../app/layout"
import Seo from "../../components/seo"
import Laporan from "../../components/Laporan"
import Container from "../../components/Container"
import { ChevronRight } from "../../components/Icon"

import "./report.less"

const LaporanLainPage = ({
  data: {
    allLaporanLain: { nodes: data },
    reportSettings: { laporan_lain: settings = {} },
  },
}) => {
  const { Item } = Breadcrumb
  const { Search } = Input
  const [more, setMore] = useState(0)
  const [lists, setLists] = useState([])
  const { t } = useI18next()
  const [filter, setFilter] = useState({
    search: "",
  })
  const filtered = useMemo(() => {
    let results = [...data]

    if (filter.search) {
      results = results.filter(item =>
        item.title.toLowerCase().includes(filter.search.toLowerCase())
      )
    }

    setLists([...results].slice(0, (more + 1) * 12))

    return results
  }, [filter])

  return (
    <Layout module_name="information" page_name="laporan-lain">
      <Seo
        title="Laporan Lainnya"
        module_name="information"
        page_name="laporan-lain"
      />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Item>
            <Link to="/">{t("Beranda")}</Link>
          </Item>
          <Item>{settings.page_title}</Item>
        </Breadcrumb>
      </Container>

      <div className="section text-medium section-padding-top-less report-form">
        <Container>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h1 className="section-title-huge">{settings.page_title}</h1>
          </ScrollAnimation>
        </Container>

        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <div className="filter-form">
            <Container>
              <Row>
                <Col span={24} md={24}>
                  <Search
                    onSearch={e => setFilter(p => ({ ...p, search: e }))}
                    placeholder={t("Cari laporan...")}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </ScrollAnimation>
      </div>

      <Container>
        <Row gutter={40} style={{ margin: "24px -15px 0 -15px" }}>
          {filtered.map((item, i) => (
            <Col span={24} md={6} key={i} style={{ padding: "0 15px" }}>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <Laporan
                  title={item.title}
                  image={item.thumbnail_image}
                  fileurl={item.file}
                />
              </ScrollAnimation>
            </Col>
          ))}
        </Row>
      </Container>

      <Container>
        {lists.length !== filtered.length && filtered.length > 12 && (
          <div className="text-center" style={{ marginTop: 30 }}>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <Button
                type="link"
                onClick={() => setMore(more + 1)}
                style={{ textTransform: "capitalize", direction: "ltr" }}
              >
                {t("...Muat Lebih")}
              </Button>
            </ScrollAnimation>
          </div>
        )}
      </Container>
    </Layout>
  )
}

export default LaporanLainPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allLaporanLain(filter: { lang: { eq: $language } }) {
      nodes {
        thumbnail_image
        title
        file
      }
    }
    reportSettings(lang: { eq: $language }) {
      laporan_lain {
        page_title
        page_name
        slug
      }
    }
  }
`
